import React from 'react';
import { v4 as uuidv4 } from 'uuid';
import parse, { domToReact } from 'html-react-parser';
import { HashLink } from 'react-router-hash-link';
import { createRichTextHtmlResolver, linkedItemsHelper } from '@kontent-ai/delivery-sdk';
import { CONTENT_ITEM_TYPES as TYPE, DOMAIN_LIST, IS_FOR_EXPORT_PDF, KONTENT_EMPTY_DATA_VALUES, MANDATORY_REQUIREMENTS_CHAPTER_TITLE, IS_PREVIEW_MODE } from 'Constants';
import { DownloadFileButton, FeedbackForm, ImageView, LinkCopy, PdfEmbed } from 'Components';
import { correctLinkNode, getUrlObject, innerPageScroll, titleToUrl } from 'Utils';
import icons from 'Assets/svgSprite.svg';

/**
 * Function to replace target tags with new given tag while maintaining inner html
 * @param {String} rawHtmlString Raw html string
 * @param {String} target Target html tag in string with css selector format
 * @param {String} replaceWith New html tag that would replace the target tag
 * @param {{ [attribute]: String }} [attributes] Attributes to apply along with replacing tag
 * @returns {String} Raw html string with target tags replaced with new tag
 */
const replaceTagsInHtmlString = (rawHtmlString, target, replaceWith, attributes = {}) => {
  const dummyHtml = new DOMParser().parseFromString(rawHtmlString, 'text/html');
  const targetDoms = dummyHtml.querySelectorAll(target);

  targetDoms.forEach((targetTag) => {
    const newTag = document.createElement(replaceWith);

    Object.keys(attributes).forEach((attribute) => {
      newTag.setAttribute(attribute, attributes[attribute]);
    });

    newTag.innerHTML = targetTag.innerHTML;
    targetTag.parentNode.replaceChild(newTag, targetTag);
  });

  return String(dummyHtml.querySelector('body').innerHTML);
};
/**
 * Removes all given tags and rplaces it with their children tag/text
 * @param {String} rawHtmlString Raw html string
 * @param {String} target target to replace string
 * @param {String[]} ignoreTagsWithChildren - Array of tag types to ignore which contain children
 * @returns {String} Raw html string with target tags removed (with their children are added in)
 */
const removeParentTags = (rawHtmlString, target, ignoreTagsWithChildren) => {
  const dummyHtml = new DOMParser().parseFromString(rawHtmlString, 'text/html');
  const targetDoms = dummyHtml.querySelectorAll(target);

  targetDoms.forEach((targetTag) => {
    let hasLinkAsChild = false;
    targetTag.childNodes.forEach((node) => {
      if (ignoreTagsWithChildren.includes(node?.nodeName?.toLowerCase())) {
        hasLinkAsChild = true;
      }
    });
    if (hasLinkAsChild) {
      targetTag.replaceWith(...targetTag.childNodes);
    }
  });

  return String(dummyHtml.querySelector('body').innerHTML);
};

/**
 * Function to replace target tags with new footnote <sup> links
 * @param {String} rawHtmlString Raw html string
 * @param {String} target Target html tags to replace
 * @param {{text: String, id: String, selfId: String}[]} footnoteCitationList footnote citation list containing citation objects with text, id (targetId) and selfId
 * @returns Raw html string with target tags replaced with footnote superscript links
 */
const replaceFootnoteItems = (rawHtmlString, target, footnoteCitationList) => {
  const dummyHtml = new DOMParser().parseFromString(rawHtmlString, 'text/html');
  const targetDoms = dummyHtml.querySelectorAll(target);

  targetDoms.forEach((targetTag) => {
    const targetId = targetTag.getAttribute('href').replace(/\s/g, '');

    const foundFootnote = footnoteCitationList.find((footnote) => footnote.id === targetId);

    // self assigned uuid in order for link to target back to this footnote
    let selfId;
    if (foundFootnote) {
      selfId = foundFootnote.selfId;
    }

    let content = targetTag.innerHTML;

    if (targetTag.innerHTML.includes('<sup>')) {
      content = content.replace(/<[^>]*>?/gm, ''); // replace <sup> tags
    }

    const newElementString = `
      <sup id="${selfId}">
        <a href="${targetId}" aria-describedby="${targetId}">${content}</a>
      </sup>
    )`;
    const newElement = new DOMParser().parseFromString(newElementString, 'text/html').body.firstElementChild;

    targetTag.parentNode.replaceChild(newElement, targetTag);
  });

  return String(dummyHtml.querySelector('body').innerHTML);
};

/**
 * Function to update href url in <a> tags to current domain when specifc domains have been given
 * @param {String} rawHtmlString Raw html string
 * @returns Html string with <a> href updated to current domain
 */
const updateTableReferenceLinks = (rawHtmlString) => {
  const dummyHtml = new DOMParser().parseFromString(rawHtmlString, 'text/html');
  const targetDoms = dummyHtml.querySelectorAll('a');

  targetDoms.forEach((targetTag) => {
    let urlLink = targetTag.getAttribute('href');

    const urlObject = getUrlObject(urlLink);
    if (urlObject) {
      if (DOMAIN_LIST.includes(urlObject.origin)) {
        // remove all whitespace from the hash
        urlLink = `${window.location.origin + urlObject.pathname + urlObject.hash.replace(/\s/g, '')}`;
      }
    }
    targetTag.setAttribute('href', urlLink);
  });

  return String(dummyHtml.querySelector('body').innerHTML);
};

/**
 * Function to generate html string of footnote content
 * @param {{elements: Object, system: Object}[]} footnoteContentList Array of objects from delivery API SDK of footnote content in order
 * @param {{id: String, text: String}[]} citationList Array of object of footnote content citation character in order
 * @returns {String} Raw html string of footnotes
 */
const generateFootnoteContents = (footnoteContentList, citationList) => {
  const htmlString =
    '<ol class="footnotesWrapper">' +
    citationList
      .map((citation) => {
        const id = citation.id?.replace('#', '');
        const targetTextId = citation.selfId;
        const footnoteContent = footnoteContentList.find((footnote) => {
          const replaceString = '#';
          return id.includes(footnote.elements?.footnote_link_id?.value?.replace(replaceString, '')) || id.includes(footnote?.FootnoteLinkId?.replace(replaceString, ''));
        });

        if (footnoteContent) {
          const dummyHtml = new DOMParser().parseFromString(footnoteContent?.elements?.footnote_text?.value || footnoteContent?.FootnoteText, 'text/html');
          const dummyBody = dummyHtml.querySelector('body').firstChild;
          let superscript;
          if (citation.text?.includes('<sup>')) {
            superscript = citation.text;
          } else {
            superscript = `<sup>${citation.text}</sup>`; // if it does not include superscript, add <sup> tags
          }
          dummyBody.insertAdjacentHTML('afterbegin', superscript);
          dummyBody.insertAdjacentHTML('beforeend', `<a class="footnote" href="#${targetTextId}" aria-label="Back to content">↩</a>`); // add in footnote links to link back to footnote items in text

          const footnoteContentInHtml = String(dummyHtml.querySelector('body').innerHTML);

          return `<li id="${footnoteContent.FootnoteLinkId?.replace('#', '')}">${footnoteContentInHtml}</li>`;
        }
      })
      .join('') +
    '</ol>';

  return htmlString;
};

/**
 * Function to generate complete html string of table html
 * @param {String} rawTableHtmlString Raw table html string
 * @param {{elements: Object, system: Object}[]} [footnoteContentData] Array of objects of footnote content within given table
 * @param {String} [tableName] Table name (table title)
 * @param {{renderAsText: Boolean}} [extra] Object with extra details (currently has renderAsText to render links as text in tables)
 * @returns {String|Null} Raw table html string. If @param rawTableHtmlString does not contain <table>, return null
 */
export const generateTableHtml = (rawTableHtmlString, footnoteContentData, extra = {}) => {
  /**
   * replace a tag with href*="#footnote_" with <sup/>
   * NOTE: decision made with BE that with footnote inside table always come in to FE as a tag with href target
   * id starts with `footnote_` due to Kontent.ai limitation as time of development.
   */
  const rawHtml = new DOMParser().parseFromString(rawTableHtmlString, 'text/html');
  const footnoteCitationList = [];
  rawHtml.querySelectorAll('a[href*="#footnote"]').forEach((tag) => {
    footnoteCitationList.push(tag.getAttribute('href'));
  });
  const citation = [];
  rawHtml
    .querySelectorAll('a[href*="#footnote"]')
    .forEach((element, i) => citation.push({ text: element.innerHTML, id: footnoteCitationList[i]?.replace('#footnote-', ''), selfId: uuidv4() }));

  /**
   * Below function removes superscript parent tags that may come from Kontent CMS.
   * Since the structure of the superscript will depend on the content editor, there is a situation where content editor can add a superscript element and then a link,
   * which causes link to be wrapped in <sup> tags.
   * This removes the tag, while keeping the <a> link contents, so that FE can apply its own <sup> tag.
   * NOTE: the order in which the content editor will add the superscript link is unknown, so FE always removes it (if seen) to apply own our style.
   */
  const removedSup = removeParentTags(rawTableHtmlString, 'sup', ['a']);

  const htmlWithCorrectSupTag = replaceFootnoteItems(removedSup, 'a[href*="#footnote"]', citation);
  let htmlWithUpdatedLinks = updateTableReferenceLinks(htmlWithCorrectSupTag);

  if (extra.renderAsText) {
    // if only render as text, replace all <a> tags with <u> tags
    htmlWithUpdatedLinks = replaceTagsInHtmlString(htmlWithUpdatedLinks, 'a', 'u');
  }
  const dummyHtml = new DOMParser().parseFromString(htmlWithUpdatedLinks, 'text/html');
  const table = dummyHtml.querySelector('table'); // always assume passed raw html string contains <table>

  // if table width is over 100%, add class for pdf export
  if (Number(table?.style?.width?.slice(0, -1)) > 100) {
    table.classList.add('wideTable');
  }

  if (table) {
    // create footnote contents
    if (Array.isArray(footnoteContentData) && footnoteContentData.length > 0) {
      const tableFootnotes = generateFootnoteContents(footnoteContentData, citation);

      // append footnote contents within table
      table.insertAdjacentHTML('afterend', tableFootnotes);
    }

    return String(dummyHtml.querySelector('body').innerHTML);
  }

  return null;
};

/**
 * Function to create web data table (different from table element form CMS)
 * @param {String} tableBaseHtmlString Table base html string. This must include one <table> element
 * @param {{ web_table_rendering_options: Object, [any_key]: Object }} tableData Table data returned from API. Must contain `web_table_rendering_options`
 * @param {Boolean} [appendRows = true] If true consider table render type is append row, else, insert fixed. By default true
 * @returns {String} HTML string of table
 */
export const processWebTableContents = (tableBaseHtmlString, tableData, appendRows = true) => {
  const table = new DOMParser().parseFromString(tableBaseHtmlString, 'text/html').querySelector('table'); // always assume there is a single table
  table.className = 'clientGenerated';
  // if data type is 'date_time', convert format to `dd/mm/yyyy`. if `dd` and `mm` starts with 0, display without 0. also ignore timezone and time as agreed with Finance.
  const formatDateTimeData = (dataItem) => {
    const dateItemFormat = /^(\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}Z)$/;
    const isDataItemIsDateTimeString = dateItemFormat.test(dataItem);

    if (dataItem.type === 'date_time' || isDataItemIsDateTimeString) {
      const data = new Date(isDataItemIsDateTimeString ? dataItem : dataItem.value)
        ?.toLocaleDateString('en-GB', { timeZone: 'Australia/Sydney' })
        ?.split(',')?.[0]
        ?.split('/')
        ?.map((value) => Number(value));

      const formattedData = data?.join('/');

      if (isDataItemIsDateTimeString) {
        dataItem = formattedData;
      } else {
        dataItem.value = formattedData;
      }
    }

    return dataItem;
  };

  if (appendRows) {
    // if table render type is: 'append rows'
    table.insertAdjacentElement('beforeend', document.createElement('tbody'));
    table.className += ' webDataTableAppendRow';
    const tbody = table.querySelector('tbody');

    // Notes: Table columns in Kontent must have scope of column for it to be rendered
    // only consider <th> with scope to "col" represents each cells from table body's column head
    const columnheaderCells = table.querySelectorAll('th[scope="col"]');

    // create column order for each table body row (Node list does not support forEach() hence using for loop)
    const columnOrder = [];
    for (let i = 0; i < columnheaderCells.length; i++) {
      const style = columnheaderCells[i].getAttribute('style');

      columnOrder.push({
        text: columnheaderCells[i].innerText?.toLowerCase()?.replace(/[\s\n]/g, ''),
        id: columnheaderCells[i].id,
        style: style + ' background: none; background-image: none; background-color: unset',
      });
    }

    // create complete row with table data in it
    const rowItems = tableData?.row_items?.linkedItems || tableData?.RowItems;
    rowItems?.forEach((rowItem) => {
      const row = document.createElement('tr');

      // convert any table data with date time in correct format (dd/MM/yyyy) and time zone in sydney
      if (rowItem?.elements) {
        Object.values(rowItem?.elements).forEach((item) => {
          formatDateTimeData(item);
        });
      } else {
        Object.keys(rowItem).forEach((key) => {
          if (typeof rowItem[key] === 'string') {
            rowItem[key] = formatDateTimeData(rowItem[key]);
          }
        });
      }

      columnOrder.forEach((col) => {
        // each column id matches with elements object's keys as agreed with BE
        let dataToDisplay;

        // ids of columns should be the codenames of matching data table row
        // any ids not given will automatically assume it is a concat string column
        if (col.id) {
          dataToDisplay =
            rowItem?.elements?.[col.id]?.value ||
            rowItem?.[
              col.id
                ?.split('_')
                ?.filter(Boolean)
                ?.map((str) => str[0]?.toUpperCase() + str?.slice(1))
                ?.join('')
            ];

          // update each th ids to be unique for accessibility
          table.querySelector(`[id="${col.id}"]`)?.setAttribute('id', `${col.id}-${uuidv4()}`);
        } else {
          // if `col.id` is undefined or empty string, meaning we have to look at web table concat strings under web table rendering option
          // always assume there is only one linked item in rendering options
          const concatString =
            tableData?.web_table_rendering_options?.linkedItems?.[0]?.elements.web_table__concat_strings?.linkedItems?.find(
              (concatStringItem) => concatStringItem?.elements?.table_column?.value?.toLowerCase()?.replace(/[\s\n]/g, '') === col.text,
            )?.elements?.item_fields?.value ||
            tableData?.WebTableRenderingOptions?.[0]?.WebTableConcatStrings?.find(
              (concatStringItem) => concatStringItem?.TableColumn?.toLowerCase().replace(/[\s\n]/g, '') === col.text,
            )?.ItemFields;

          // as concat string item field value is returned as `[key1] [key2] [key3]` etc, replacing `[` and `]` with empty space, split the string by empty space and remove to just get string key
          dataToDisplay = concatString
            ?.replace(/[[\]]/g, ' ')
            .split(' ')
            .filter(Boolean)
            .map((key) => {
              if (key === ',') {
                return key;
              }

              const data =
                rowItem?.elements?.[key]?.value ||
                rowItem[
                  key
                    ?.split('_')
                    ?.filter(Boolean)
                    ?.map((str) => str[0]?.toUpperCase() + str?.slice(1))
                    ?.join('')
                ];

              if (data) {
                return data;
              }

              return undefined;
            })
            .filter(Boolean)
            .join(' ')
            .replace(/\s,\s/gm, ', ') // if concat string had comma in, as result of join(), it would be wraped around white space hence remove first white space
            .trim(); // trim space at the start and end in case data was not given

          const dataToDisplayCommaSeperated = dataToDisplay?.split(',');
          if (dataToDisplayCommaSeperated?.[dataToDisplayCommaSeperated?.length - 1] === '') {
            dataToDisplay = dataToDisplay?.slice(0, -1); // if string ends with comma, remove it
          }
        }

        row.insertAdjacentHTML('beforeend', `<td style="${col.style}">${dataToDisplay ? dataToDisplay : ''}</td>`);
      });

      tbody.insertAdjacentElement('beforeend', row);
    });
  } else {
    // if table render type is: 'insert fixed'
    // get all table data keys to insert data in table cells
    const tableDataCells = table.querySelectorAll('td[id]');

    // get all ids which would be equal to data key from API return (Node list does not support forEach() hence using for loop)
    const tableDataCellsId = [];
    for (let i = 0; i < tableDataCells.length; i++) {
      tableDataCellsId.push(tableDataCells[i].getAttribute('id'));
    }

    // insert/replace cell text with correct data
    tableDataCellsId.forEach((key) => {
      const targetCell = table.querySelector(`[id="${key}"]`);
      const altKey = key
        .split('_')
        .filter(Boolean)
        .map((str) => str[0]?.toUpperCase() + str.slice(1))
        .join('')
        .split('-')[0];

      // if target data is date time, convert it's format
      if (tableData[key] || tableData[altKey]) {
        formatDateTimeData(tableData[key] || tableData[altKey]);
      }

      const dataToDisplay = tableData[key]?.value || tableData[altKey] || '';
      targetCell.innerText = dataToDisplay;

      // update table cell id to be unique
      targetCell.setAttribute('id', `${key}-${uuidv4()}`);
    });
  }

  return table.outerHTML;
};

/**
 * Function to process fetched content item response data from Kontent Delivery API SDK
 * @param {[{ elements: Object, system: Object}]} data List of items
 * @param {Object[]} fullLinkedItemList Full list of linked items from API response
 * @param {Object} extra Any extra data may needed for process
 * @returns {{data: Object, processedSectionNodes: Object[]}} Returns original data as `data` and
 *  processed data to consumed by react components as `processedSectionNodes`, which is array of objects
 */
const processContentItem = (data, fullLinkedItemList, extra = {}, FeProcessCrossRefLink = true) => {
  const processedSectionNodes = [];
  data.forEach((linkedItem) => {
    const body = linkedItem.elements.body;

    if (KONTENT_EMPTY_DATA_VALUES.includes(body?.value)) {
      body.value = '';
    }

    // render mandatory requirement section
    if (linkedItem.system?.type === TYPE.MANDATORY_REQUIREMENT_REPORT_SECTION.ID) {
      const node = parse(body.value, {
        replace: (domNode) => {
          return correctLinkNode(domNode, body.value, extra);
        },
      });
      const newObj = { ...linkedItem.elements };
      delete newObj.body;

      processedSectionNodes.push({ ...newObj, node });
    } else {
      const resolvedRichText = createRichTextHtmlResolver().resolveRichText({
        element: body,
        linkedItems: linkedItemsHelper.convertLinkedItemsToArray(fullLinkedItemList),
        contentItemResolver: (itemId, contentItem) => {
          let contentItemHtml = '<NO-CONTENT-ITEM>';
          let alignment = '';
          let padding = '';
          let wrapText = '';
          let urlObject = null;
          let width = '1';
          let height = '1';

          if (contentItem) {
            const elements = contentItem?.elements;
            const dataTableElements = elements?.data_table?.linkedItems?.[0]?.elements; // always expect only one item
            const webTableExclusiveElements = dataTableElements?.web_table_rendering_options?.linkedItems?.[0]?.elements; // always expect only one linked item in web_table_rendering_options

            if (webTableExclusiveElements) {
              // render data table
              const webTableType = webTableExclusiveElements?.web_table__table_type?.value?.[0]?.codename?.toLowerCase(); // expect always one item as it is either "fixed table" or "row based table"
              const tableBaseHtmlString = webTableExclusiveElements?.web_table__web_table_base?.value; // always expect this to exist

              if (tableBaseHtmlString) {
                const tableHtmlString = processWebTableContents(tableBaseHtmlString, dataTableElements, webTableType === 'append___rows');
                const id = `${contentItem.elements?.link_id?.value || contentItem.system.id}-${uuidv4()}`;
                const dataTableFootnote = KONTENT_EMPTY_DATA_VALUES.includes(elements?.footnote?.value) ? undefined : elements?.footnote?.value;
                // as display title option in CMS is a checkbox, if it is checked, meaning value array length is always 1, else 0. hence FE only cares its length rather than its value object data
                const displayTableTitle = Array.isArray(elements?.display_title?.value) && elements?.display_title?.value?.length > 0;

                contentItemHtml = `
                <div className="${TYPE.TABLE.CLASS_NAME}${elements?.title?.value ? ' haveCaption' : ''}" id="${id}" data-system-id="${contentItem.system.id}">
                  ${displayTableTitle ? `<figcaption className="tableTitle h4">${dataTableElements?.title?.value}</figcaption>` : ''}
                  <div className="tableOverflowWrapper">
                    ${generateTableHtml(tableHtmlString, undefined, extra)}
                  </div>
                </div>
                ${dataTableFootnote ? dataTableFootnote : ''}
                `;
              }
            } else {
              const targetId = contentItem.elements?.link_id?.value || contentItem.system.id;

              switch (contentItem.system.type) {
                // Indent custom element
                case TYPE.INDENT.ID:
                  contentItemHtml = `<div className="${TYPE.INDENT.CLASS_NAME}">${elements.indent.value}</div>`;
                  break;

                // Image custom element
                case TYPE.IMAGE.ID:
                  // Note: image can be cross reference hyperlink target
                  alignment = elements.alignment?.value?.length > 0 ? elements.alignment.value[0].codename : '';
                  padding = elements.image_padding?.value ?? '';
                  wrapText = elements.text_wrapping?.value?.length > 0 ? elements.text_wrapping?.value[0].codename : '';
                  width = elements.image?.value?.[0]?.width || '1';
                  height = elements.image?.value?.[0]?.height || '1';

                  contentItemHtml = elements.image.value
                    .map((img, i) => {
                      const imageSrc = `${img.url}?${img?.renditions?.default?.query || ''}`;
                      return `
                        ${IS_FOR_EXPORT_PDF ? `<span class="invisibleForPdfExport" aria-hidden>${contentItem.system.id}</span>` : ''}
                        <div id="${targetId}" data-system-id="${contentItem.system.id}" className="${TYPE.IMAGE.CLASS_NAME}" ${TYPE.IMAGE.SRC}="${imageSrc}"
                         ${TYPE.IMAGE.DESCRIPTION}="${elements.description?.value || ''}" ${TYPE.IMAGE.WIDTH}=${width} ${TYPE.IMAGE.HEIGHT}=${height}
                        ${TYPE.IMAGE.KEY}="${itemId}-${i}" ${TYPE.IMAGE.ALT_TEXT}="${elements.alt_text?.value || ''}" 
                        ${TYPE.IMAGE.ALIGN}="${alignment}" ${TYPE.IMAGE.PADDING}="${padding}" ${TYPE.IMAGE.WRAP_TEXT}="${wrapText}"/>
                      `;
                    })
                    .join();
                  break;

                // Table custom element
                case TYPE.TABLE.ID:
                  // Note: table can be cross reference hyperlink target
                  contentItemHtml = `
                  ${
                    IS_FOR_EXPORT_PDF
                      ? `
                    <span class="invisibleForPdfExport" aria-hidden>${contentItem.system.id}</span>`
                      : ''
                  }
                    <div className="${TYPE.TABLE.CLASS_NAME} ${elements?.table_name?.value ? 'haveCaption' : ''}" id="${targetId}" data-system-id="${contentItem.system.id}">
                      ${elements?.table_name?.value ? `<figcaption className="tableTitle h4">${elements?.table_name?.value}</figcaption>` : ''}
                      <div className="tableOverflowWrapper">
                      ${generateTableHtml(elements.table.value, elements?.table_footnote?.linkedItems, extra)}
                      </div>
                    </div>
                  `;
                  break;

                // Linkable section custom element
                case TYPE.LINKABLE_SECTION.ID:
                  // Note: linkable section can be cross reference hyperlink target
                  contentItemHtml = `
                  ${
                    IS_FOR_EXPORT_PDF
                      ? `
                    <span class="invisibleForPdfExport" aria-hidden>${contentItem.system.id}</span>`
                      : ''
                  }
                    <div id="${elements?.link_id?.value || contentItem.system.id}" data-system-id="${contentItem.system.id}" className="${TYPE.LINKABLE_SECTION.CLASS_NAME}">${
                    elements.text.value
                  }</div>
                  `;
                  break;

                case TYPE.LIST.ID:
                  if (!KONTENT_EMPTY_DATA_VALUES.includes(elements?.list_element?.value)) {
                    contentItemHtml = elements.list_element.value;
                  }
                  break;

                // In case List of Requirements is used as chapter/section body content
                case TYPE.MANDATORY_REQUIREMENT_REPORT_SECTION.ID:
                  contentItemHtml =
                    extra?.chapterList?.find((obj) => {
                      return obj?.system?.type === TYPE.MANDATORY_REQUIREMENT_REPORT_SECTION.ID;
                    })?.elements?.body?.value || '';

                  // remove list of requirements chapter from chapter list
                  if (contentItemHtml !== '') {
                    extra.chapterList = extra.chapterList.filter((obj) => {
                      return obj?.system?.type !== TYPE.MANDATORY_REQUIREMENT_REPORT_SECTION.ID;
                    });
                  }
                  break;

                case TYPE.LINK.ID:
                  urlObject = getUrlObject(elements?.url?.value);
                  contentItemHtml = `
                    <a class="card inBodyContent ${elements?.max_width?.value?.[0]?.codename?.toLowerCase() === 'max_width' ? 'maxWidth' : ''}" target="${
                    elements?.open_in_new_tab?.value?.[0]?.codename?.toLowerCase() === 'yes' ? '_blank' : null
                  }" rel="${elements?.open_in_new_tab?.value?.[0]?.codename?.toLowerCase() === 'yes' ? 'nonreferer' : null}" href="${
                    DOMAIN_LIST.includes(urlObject?.origin) ? `${urlObject.pathname}${urlObject.hash}${urlObject.search ? urlObject.search : ''}` : elements?.url?.value
                  }">
                      <span class="limitTextLines" style="--MAX-LINE: 4;">
                        <span class="newTabIconWrapper">
                          <strong class="cardTitle" style="--MAX-LINE: 2;">${elements?.title?.value}</strong>
                          ${
                            elements?.open_in_new_tab?.value?.[0]?.codename?.toLowerCase() === 'yes'
                              ? `<svg class="newTabIcon"><use href="${icons + '#open-in-new-tab'}"/></svg>`
                              : ''
                          }
                        </span>
                        <span class="description">${elements?.summary?.value}</span>
                      </span>
                    </a>
                  `;
                  break;

                case TYPE.FILE.ID:
                  contentItemHtml = '';
                  elements?.file?.value?.forEach((file) => {
                    contentItemHtml += `<object data-type="${TYPE.FILE.ID}" 
                    ${TYPE.FILE.FILE_NAME}="${file?.name}" 
                    ${TYPE.FILE.FILE_SIZE}="${file?.size}" 
                    ${TYPE.FILE.URL}="${file?.url}" 
                    ${TYPE.FILE.FULL_WIDTH}="${elements?.download_button_width?.value?.[0]?.codename?.toLowerCase() === 'button_full_width'}"></object>`;
                  });
                  break;

                case TYPE.FEEDBACK_FORM.ID:
                  contentItemHtml = `<div id="${TYPE.FEEDBACK_FORM.ID}"></div>`;
                  break;

                default:
                  break;
              }
            }
          }

          return { contentItemHtml };
        },
      });

      // process page footnote
      const proccessedWithPageFootnote = processPageFootnoteContent(linkedItem?.elements?.footnotes?.linkedItems, resolvedRichText.html);
      const pageFootnoteNode = proccessedWithPageFootnote?.pageFootnoteNode;
      const pageHtmlString = proccessedWithPageFootnote?.updatedPageHtmlString || resolvedRichText.html;

      // parse html string to react elements
      const node = parseRichTextHtml(pageHtmlString, extra, false, FeProcessCrossRefLink);

      const newObj = { ...linkedItem?.elements, systemData: linkedItem?.system };
      delete newObj.body;
      processedSectionNodes.push({ ...newObj, resolvedRichText, node, pageFootnoteNode });
    }
  });

  return { data, processedSectionNodes };
};

/**
 * Function to take html string and convert to node for react to use
 * @param {String} htmlString HTML string
 * @param {Object} [extra] Any extra data may needed for process
 * @param {Boolean} [isExportPdf] Check if is for export pdf of not. By default follow `IS_FOR_EXPORT_PDF` value. If true, parser will not swap image placeholder tag to react image component as it would be handled in AR page
 * @param {Boolean} [FeProcessCrossRefLink=true] If true, FE is processing cross reference link. by default true.
 * @param {Boolean} [renderLinksAsText] If true, render links as text.
 * @return {Node} Return nodes
 */
export const parseRichTextHtml = (htmlString, extra = {}, isExportPdf = IS_FOR_EXPORT_PDF, FeProcessCrossRefLink = true, renderLinksAsText) => {
  if (typeof htmlString === 'string') {
    const node = parse(htmlString, {
      replace: (domNode) => {
        const id = domNode?.attribs?.id?.replaceAll('#', '');
        const dataSystemId = domNode?.attribs?.['data-system-id'];
        // Replace image with ImageView component
        if ((domNode?.attribs?.classname === TYPE.IMAGE.CLASS_NAME || domNode?.attribs?.class === TYPE.IMAGE.CLASS_NAME) && id && domNode?.attribs?.[TYPE.IMAGE.SRC]) {
          /* NOTE: just for image, in export pdf env, return as itself without swap to react component as export pdf env need "lazy loading" of images to reduce server download load.
           * Please check `AnnualReportArticle.jsx` how it handles there.
           */
          if (isExportPdf) {
            return domNode;
          }

          return (
            <ImageView
              id={id}
              height={isNaN(domNode.attribs[TYPE.IMAGE.HEIGHT]) ? 1 : Number(domNode.attribs[TYPE.IMAGE.HEIGHT])} // set to 1px height if none given (1 instead of zero since calculation cannot divide by 0)
              width={isNaN(domNode.attribs[TYPE.IMAGE.WIDTH]) ? 1 : Number(domNode.attribs[TYPE.IMAGE.WIDTH])} // set to 1px width
              systemId={dataSystemId}
              key={domNode.attribs[TYPE.IMAGE.KEY]}
              altText={domNode.attribs[TYPE.IMAGE.ALT_TEXT]}
              imgSrc={domNode.attribs[TYPE.IMAGE.SRC]}
              description={domNode.attribs[TYPE.IMAGE.DESCRIPTION]}
              padding={domNode.attribs[TYPE.IMAGE.PADDING]}
              alignment={domNode.attribs[TYPE.IMAGE.ALIGN]}
              wrapText={domNode.attribs[TYPE.IMAGE.WRAP_TEXT]}
              disabledLargerImage={extra.disableOpenLargeImage || false}
            />
          );
        }

        // Wrap table with link copy wrapper and have sibling link copy component
        if ((domNode?.attribs?.classname?.includes(TYPE.TABLE.CLASS_NAME) || domNode?.attribs?.class?.includes(TYPE.TABLE.CLASS_NAME)) && id) {
          const formattedChildNode = parseChildDomNodeToRichText(domNode, htmlString, extra, FeProcessCrossRefLink);
          const tableIndentation = domNode?.attribs[TYPE.TABLE.INDENT];
          const indentAmount = Math.floor(Number(tableIndentation || 0));

          return (
            <>
              {isExportPdf && <span className="invisibleForPdfExport">{dataSystemId}</span>}
              <div
                className="linkCopyWrapper indentElement"
                style={{ '--INDENT-AMOUNT': indentAmount !== Infinity && indentAmount >= 0 && indentAmount <= 5 ? indentAmount : 0 }}
                id={id}
                data-system-id={dataSystemId}>
                <figure className={TYPE.TABLE.CLASS_NAME}>{formattedChildNode || domToReact(domNode.children)}</figure>
                <LinkCopy targetId={id} />
              </div>
            </>
          );
        }

        // Update Linkable Section component
        if ((domNode?.attribs?.classname === TYPE.LINKABLE_SECTION.CLASS_NAME || domNode?.attribs?.class === TYPE.LINKABLE_SECTION.CLASS_NAME) && id) {
          const formattedChildNode = parseChildDomNodeToRichText(domNode, htmlString, extra, FeProcessCrossRefLink);
          return (
            <>
              {isExportPdf && <span className="invisibleForPdfExport">{dataSystemId}</span>}
              <div id={id} className={TYPE.LINKABLE_SECTION.CLASS_NAME} data-system-id={dataSystemId}>
                {formattedChildNode || domToReact(domNode.children)}
                <LinkCopy targetId={id} />
              </div>
            </>
          );
        }

        if (id === TYPE.FEEDBACK_FORM.ID) {
          return <FeedbackForm></FeedbackForm>;
        }

        // replace cross refereing hyperlinks
        if (domNode.name === 'a' && domNode.type === 'tag') {
          const attribs = domNode.attribs;
          if (FeProcessCrossRefLink) {
            return correctLinkNode(domNode, htmlString, extra);
          } else if (attribs?.['data-footnote-link'] || attribs?.['data-item-id'] || (attribs?.['data-crossRef'] && attribs?.['data-crossRef'].toLowerCase() === 'true')) {
            return correctLinkNode(domNode, htmlString, extra);
          }

          if (renderLinksAsText) {
            return <u>{domToReact(domNode.children)}</u>;
          }

          const classNames = attribs?.class;

          const openInNewTab = domNode?.attribs?.target === '_blank';
          const urlObject = getUrlObject(domNode?.attribs?.href);
          const hash = urlObject?.hash;

          // For migrated reports, link is populated with x ref link attribute
          // BE still also has this value in their links `domNode?.attribs?.['data-crossRef']?.toLowerCase() = 'true'`
          if (attribs?.['data-migrated-x-ref-link']) {
            const urlPart = attribs['data-migrated-x-ref-link'].split('#');

            const getCurrentPath = () => {
              let pathname = '';
              const currentPathname = window.location.pathname.split('/').filter(Boolean);
              if (currentPathname.length >= 4) {
                const pathUrlList = currentPathname.slice(0, 4);
                pathname = pathUrlList.join('/');
              }

              return pathname;
            };

            const newTabIcon = (
              <svg className="newTabIcon">
                <use href={`${icons}#open-in-new-tab`} />
              </svg>
            );

            return (
              <HashLink
                className={classNames}
                to={`/${getCurrentPath()}${urlPart[0]
                  .split('/')
                  .map((str) => titleToUrl(str))
                  .join('/')}${urlPart?.[1] ? `#${urlPart[1]}` : ''}`}
                target={openInNewTab ? '_blank' : null}
                rel={openInNewTab ? 'nonreferer' : null}>
                {domToReact(domNode.children)}
                {openInNewTab && !classNames?.includes('inBodyContent') && newTabIcon}
              </HashLink>
            );
          }
          // always return hash link for all links coming from BE
          return (
            <HashLink
              to={domNode?.attribs?.href || window.location.href} // if href is undefined, use current page's href
              className={classNames}
              target={openInNewTab ? '_blank' : null}
              rel={openInNewTab ? 'nonreferer' : null}
              smooth
              scroll={() => innerPageScroll(`#${hash?.startsWith('#') ? hash?.slice(1) : ''}`)}>
              {domToReact(domNode.children)}

              {openInNewTab && !classNames?.includes('inBodyContent') && (
                <svg className="newTabIcon">
                  <use href={`${icons}#open-in-new-tab`} />
                </svg>
              )}
            </HashLink>
          );
        }

        // replace file download button
        if (domNode?.attribs?.['data-type'] === TYPE.FILE.ID) {
          return (
            <DownloadFileButton
              fileName={domNode?.attribs?.[TYPE.FILE.FILE_NAME]}
              fileSize={Number(domNode?.attribs?.[TYPE.FILE.FILE_SIZE])}
              fileSource={domNode?.attribs?.[TYPE.FILE.URL]}
              fullWidth={domNode?.attribs?.[TYPE.FILE.FULL_WIDTH] === 'true'}
            />
          );
        }

        // replace pdf embbed
        if (domNode?.attribs?.classname === TYPE.EMBEDDED_PDF.CLASS_NAME || domNode?.attribs?.class === TYPE.EMBEDDED_PDF.CLASS_NAME) {
          return <PdfEmbed pdfSrc={domNode?.attribs?.['data-url']} fileName={domNode?.attribs?.['data-pdftitle']}></PdfEmbed>;
        }

        // replace any unwanted <object> from kontent (to make sure not render unsupported content item type)
        // if dom node contains custom attribute `data-codename`, it indicates it is content item type
        if (domNode.name === 'object' || domNode?.attribs?.['data-codename']) {
          return <></>;
        }
      },
    });

    return node === 'undefined' ? '' : node;
  }
};

/**
 * Calls the parse rich text function on child dom nodes
 * @param {Node} domNode - Dom node to render children
 * @param {String} htmlString - Html string containing all dom nodes
 * @param {Object} extra - extra information
 * @returns {Node|null}
 */
export const parseChildDomNodeToRichText = (domNode, htmlString, extra, FeProcessCrossRefLink = true) => {
  const html = new DOMParser().parseFromString(htmlString, 'text/html');
  let htmlNode = null;
  const dataSystemId = domNode?.attribs?.['data-system-id'];
  if (html) {
    const foundTargetHtml = html.querySelector(`[data-system-id="${dataSystemId}"]`);
    if (foundTargetHtml) {
      const htmlString = String(foundTargetHtml.innerHTML);
      htmlNode = parseRichTextHtml(htmlString, extra, false, FeProcessCrossRefLink);
    }
  }
  return htmlNode;
};

/**
 * Function to update page html string with correct citation structure and page footnote contents.
 * @param {Object[]} footnoteList List of footnote list.
 * @param {String} pageHtmlString HTML string.
 * @returns {{ pageFootnoteNode: Object[], updatedPageHtmlString: String }} Return page footnote node list and updated page html string.
 */
const processPageFootnoteContent = (footnoteList, pageHtmlString, extra) => {
  // process page footnote
  const pageFootnotes = footnoteList;
  const rawBodyHtml = new DOMParser().parseFromString(pageHtmlString, 'text/html');
  const pageFootnoteCitationList = [];
  let pageFootnoteNode = null;

  rawBodyHtml.querySelectorAll('a[href*="#footnote-"]').forEach((tag) => {
    pageFootnoteCitationList.push({
      id: tag.getAttribute('href'), // id for href for destination link
      text: tag.innerHTML,
      selfId: uuidv4(), // add self id for other elements to link to this footnote
    });
  });

  if (Array.isArray(pageFootnotes) && pageFootnotes.length > 0 && pageFootnoteCitationList.length > 0) {
    // generate footnote elements at the bottom of chapter/section
    pageFootnoteNode = parseRichTextHtml(generateFootnoteContents(pageFootnotes, pageFootnoteCitationList), extra);
  }

  // for all footnotes in the section (those not in bottom of page), replace any <a> with href value starting with `#footnote` citations with <sup><a></a></sup>
  const updatedPageHtmlString = replaceFootnoteItems(pageHtmlString, 'a[href*="#footnote-"]', pageFootnoteCitationList);

  return {
    pageFootnoteNode,
    updatedPageHtmlString,
  };
};

export const sortWebDataTable = (htmlString, performanceData) => {
  const rawBodyHtml = new DOMParser().parseFromString(htmlString, 'text/html');

  rawBodyHtml.querySelectorAll('object[data-type="web data table"').forEach((tag) => {
    const webDataTableId = tag.getAttribute('data-target-system-id');
    const dataTemplateId = tag.getAttribute('id');
    const tableTitleShowenOrHiden = tag.getAttribute('data-display-table-title')?.toLowerCase();
    const showTableTitle = tableTitleShowenOrHiden ? tableTitleShowenOrHiden === 'true' : true; // by default, always display
    const targetWebDataTableObj = performanceData?.find((obj) => {
      return obj?.System?.id === webDataTableId || obj?.system?.id === webDataTableId;
    });
    const defaultStructure = targetWebDataTableObj?.elements?.web_table_rendering_options?.linkedItems?.[0]?.elements;

    const tableBaseHtmlString = targetWebDataTableObj?.WebTableRenderingOptions?.[0]?.WebTableWebTableBase || defaultStructure?.web_table__web_table_base?.value;
    // expect always one item as it is either "fixed table" or "row based table"
    const webTableType =
      targetWebDataTableObj?.WebTableRenderingOptions?.[0]?.WebTableTableType?.[0]?.codename?.toLowerCase() ||
      defaultStructure?.web_table__table_type?.value?.[0]?.codename?.toLowerCase();
    let toReplaceHtml = '';

    if (tableBaseHtmlString && webTableType) {
      // if web data table is found under "Performance data" ("Data Templates" tab of AR content item in Kontent.ai)

      const webDataTableHtml = processWebTableContents(tableBaseHtmlString, targetWebDataTableObj, webTableType === 'append___rows');
      toReplaceHtml = `
          <figure id="${dataTemplateId}" class="${TYPE.TABLE.CLASS_NAME}${targetWebDataTableObj?.Title ? ' haveCaption' : ''}" data-system-id="${webDataTableId}">
          ${targetWebDataTableObj?.Title && showTableTitle ? `<figcaption class="tableTitle h4">${targetWebDataTableObj?.Title}</figcaption>` : ''}
          <div class="tableOverflowWrapper">${webDataTableHtml}</div>
          </figure>
        `.trim();
    } else {
      // if web data table is not found under "Performance data" ("Data Templates" tab of AR content item in Kontent.ai), return 8
      toReplaceHtml = IS_PREVIEW_MODE
        ? '<p class="warning">Data table placed here is not found under "Performance data" of "Data Templates". Please add the data table there first.</p>'
        : '';
    }

    // replace html element
    const target = rawBodyHtml.querySelector(`object[data-target-system-id="${webDataTableId}"]`);
    target.outerHTML = toReplaceHtml;
  });

  return rawBodyHtml.querySelector('body').innerHTML;
};

/**
 * Fuction to sort out article content item
 * @param {Object} data Object of fetched data. It may be outcome of `processContentItem()`
 * @param {String} codename Article content item codename`
 * @returns {{data: Object, processedSectionNodes: Object[]}} Returns original data as `data` and
 *  processed data to consumed by react components as `processedSectionNodes`, which is array of objects
 */
const processArticleContent = (data) => {
  const LoRContentItem = `<object data-type="list of requirements"></object>`; // BE would return this if LoR is used as content item in body
  const extraObjToParser = {
    chapterList: data?.ReportSections,
    articleWebUrl: data?.WebUrl,
  };
  const processAllData = IS_FOR_EXPORT_PDF ? true : false;
  let LoRFound = data?.GenerateLor || 'false';

  const processResponseObj = (dataObj) => {
    let contentHtml = dataObj?.Body?.replaceAll('>&nbsp;</', '></')?.replaceAll('href="http://"', 'data-not-link="true"');

    if (KONTENT_EMPTY_DATA_VALUES.includes(contentHtml) && (!IS_PREVIEW_MODE || IS_FOR_EXPORT_PDF)) {
      contentHtml = '';
    }

    const hasLoR = contentHtml?.includes(LoRContentItem);
    const contentIsLoR = dataObj?.System?.type === TYPE.MANDATORY_REQUIREMENT_REPORT_SECTION.ID;

    // check if body contains LoR
    if (hasLoR) {
      contentHtml.replaceAll(LoRContentItem, data?.ListOfRequirementsMarkup || '');

      const sectionHtml = new DOMParser().parseFromString(contentHtml, 'text/html');
      const LoR = sectionHtml.querySelector('object[data-type="list of requirements"]');
      LoR.outerHTML = data?.ListOfRequirementsMarkup || '';
      contentHtml = sectionHtml.querySelector('body').innerHTML;
      LoRFound = 'true';
    }

    if (contentIsLoR) {
      LoRFound = 'true';
    }

    // ONLY for PDF,  if contains web data table, replace <object data-type="web data table"> with actual web data table html structure
    if (IS_FOR_EXPORT_PDF) {
      contentHtml = sortWebDataTable(contentHtml, data?.PerformanceData);
    }

    //Checking if footnote handling is required
    //report chapter
    if (dataObj?.System.type == 'report_chapter' && dataObj?.Footnotes8b896b4 != null && dataObj?.Footnotes8b896b4 != '<p><br></p>') {
      contentHtml = processAdvancedFootnotes(contentHtml, dataObj.Footnotes8b896b4);
    }
    //report section
    else if (dataObj?.System.type == 'report_sections' && dataObj?.Footnotes3fb0a4f != null && dataObj?.Footnotes3fb0a4f != '<p><br></p>') {
      contentHtml = processAdvancedFootnotes(contentHtml, dataObj.Footnotes3fb0a4f);
    }
    //no footnote
    else if (contentHtml?.includes('<div class="advancedFootnotesWrapper">') && contentHtml?.includes('<div class="advancedFootnotesBody">')) {
      contentHtml = processAdvancedFootnotes(contentHtml);
    }

    // process page footnote
    const processedWithSectionFootnote = processPageFootnoteContent(dataObj?.Footnotes, contentHtml, extraObjToParser);
    const bodyData = contentIsLoR ? data?.ListOfRequirementsMarkup || '' : processedWithSectionFootnote?.updatedPageHtmlString || contentHtml || '';

    return {
      title: contentIsLoR ? dataObj?.Title || MANDATORY_REQUIREMENTS_CHAPTER_TITLE : dataObj?.Title,
      webUrl: dataObj?.WebUrl,
      bodyDataProcessed: processAllData ? true : false,
      bodyNode: processAllData ? parseRichTextHtml(bodyData, extraObjToParser, undefined, true) : bodyData,
      pageFootnoteNode: processedWithSectionFootnote?.pageFootnoteNode,
      pdfItem: dataObj?.PdfItem || [],
      system: dataObj.System,
    };
  };

  const commonAcceptableContentTypes = [TYPE.REPORT_CHAPTER_MIGRATED.ID, TYPE.ANAO_REPORT_SECTION.ID, TYPE.MANDATORY_REQUIREMENT_REPORT_SECTION.ID];

  const routeData =
    data?.ReportSections?.filter((obj) => {
      return [TYPE.REPORT_CHAPTER.ID, ...commonAcceptableContentTypes].includes(obj.System?.type);
    })
      ?.map((chapterObj) => {
        return {
          ...processResponseObj(chapterObj),
          hasBodyContent: chapterObj?.HasBodyContent,
          sections:
            chapterObj?.Sections?.filter((obj) => {
              return [TYPE.REPORT_SECTION.ID, ...commonAcceptableContentTypes].includes(obj.System?.type);
            })
              ?.map((sectionObj) => processResponseObj(sectionObj))
              ?.filter((section) => {
                if (!IS_PREVIEW_MODE && section?.pdfItem?.length === 0 && section?.bodyNode?.length === 0 && !section?.containsLoR) {
                  // if is non preview mode and section has no pdf item nor body contents nor LoR, do not render this section
                  return false;
                }

                return true;
              })
              ?.filter(Boolean) || [],
        };
      })
      ?.filter((chapter) => {
        if (!IS_PREVIEW_MODE && chapter?.bodyNode?.length === 0 && !chapter?.containsLoR && chapter?.sections?.length === 0 && chapter?.pdfItem?.length === 0) {
          // filter out route that has no children and no chapter content if not preview mode
          return false;
        }

        return true;
      })
      ?.filter(Boolean) || [];

  // if LoR is not found, auto add LoR at the end of report chapter
  if (LoRFound == 'false') {
    routeData.push({
      title: MANDATORY_REQUIREMENTS_CHAPTER_TITLE,
      bodyDataProcessed: true,
      // bodyDataProcessed: processAllData ? true : false,
      containsLoR: true,
      bodyNode: parseRichTextHtml(data?.ListOfRequirementsMarkup || '', extraObjToParser, false, IS_PREVIEW_MODE ? true : false),
      // bodyNode: processAllData ? parseRichTextHtml(data?.ListOfRequirementsMarkup || '', extraObjToParser) : data?.ListOfRequirementsMarkup,
      sections: [],
    });
  }

  return routeData;
};

const processAdvancedFootnotes = (htmlString, footnote = null) => {
  if (!htmlString?.includes('<sup>') || typeof htmlString != 'string') {
    return htmlString;
  }

  function addIdToDiv(divString, id) {
    console.log(`addIdToDiv => ${divString}, ${id}`);
    let stringWithId = '';
    if (divString.includes('<a')) {
      const newOpeningTag = '<a id="' + id + '"';
      stringWithId = divString.replace('<a', newOpeningTag);
    } else if (divString.includes('<sup>')) {
      const newOpeningTag = '<sup id="' + id + '"';
      stringWithId = divString.replace('<sup', newOpeningTag);
    }
    return stringWithId;
  }

  function addCustomAttribute(divString, customId) {
    console.log(`addCustomAttribute => ${divString}, ${customId}`);
    const newOpeningTag = '<a href="#" data-footnote-link="' + customId.trim() + '">';
    const stringWithCustomAttribute = divString.replace('<a href="#">', newOpeningTag);

    return stringWithCustomAttribute;
  }

  function insertSecondaryString(htmlString, specificString, replacementText) {
    // Find the index of the specific string
    const specificIndex = htmlString.indexOf(specificString);

    // If the specific string is found
    if (specificIndex !== -1) {
      //reg expression to match <sup>, <br>, or </p> tags
      const regex = /(<sup>|<br>|<\/p>|<\/span>|<\/strong>|<\/tr>|<\/td>)/;
      // Find the index of the next <sup>, <br>, or </p> tag after the specific string
      const nextTagIndex = htmlString.substring(specificIndex).search(regex);

      // If a <sup>, <br>, or </p> tag is found
      if (nextTagIndex !== -1) {
        // Insert the replacement text after the specific string and before the tag
        const insertionIndex = specificIndex + nextTagIndex;
        const modifiedHtmlString = htmlString.slice(0, insertionIndex) + replacementText + htmlString.slice(insertionIndex);
        return modifiedHtmlString;
      }
    }

    // Return the original HTML string if the specific string or tag is not found
    return htmlString;
  }

  function generateRandomId() {
    const randomPart = Math.random().toString(36).substring(2, 8); // Generate a random string of length 6 (substring from index 2 to index 8)
    return randomPart;
  }

  function formatIdTag(string) {
    return string
      .replace(/<\/?sup>/g, '')
      .replace(/&nbsp;/g, '')
      .trim();
  }

  function escapeRegExp(string) {
    return string.replace(/[.*+?^${}()|[\]\\]/g, '\\$&'); // $& means the whole matched string
  }

  function processFootnoteHtmlString(individualObject, isBodyText) {
    //Converts it from a string to a html element to split the two divs easily
    let tempContainer = document.createElement('div');
    tempContainer.innerHTML = individualObject;

    //Isolates the two different divs
    let bodyText = tempContainer.querySelector('.advancedFootnotesBody');
    let footnoteText = tempContainer.querySelectorAll('.advancedFootnotesWrapper');
    footnoteText = footnoteText[footnoteText.length - 1];

    //Returns a list of elements from both divs with all the <sup> tags
    let bodyIndex = bodyText?.querySelectorAll('sup');
    let footnoteIndex = footnoteText?.querySelectorAll('sup');

    if (footnoteIndex) {
      if (isBodyText) {
        footnoteText.style.paddingTop = '20px';
      } else if (!isBodyText) {
        footnoteText.style.paddingTop = '10px';
      }
    } else {
      return individualObject;
    }

    //Converts the array of html <sup> elements to
    let bodyStrings = [];
    bodyIndex?.forEach((bodyFootnoteTag) => {
      bodyStrings.push(bodyFootnoteTag.outerHTML);
    });

    let footnoteStrings = [];
    footnoteIndex?.forEach((indexFootnoteTag) => {
      footnoteStrings.push(indexFootnoteTag.outerHTML);
    });

    //Creates new editable body/footnote strings that can be altered
    let newBodyText = bodyText?.outerHTML;
    let newFootnoteText = footnoteText?.outerHTML;

    for (let indexFootnoteTag of footnoteStrings) {
      let footnoteMatch = false;
      let bodyUniqueId = '';

      // Generating a unique id tag for footnote sup element
      let footnoteIdTag = formatIdTag(indexFootnoteTag) + '-' + generateRandomId();

      for (let i = 0; i < bodyStrings.length; i++) {
        let bodyFootnoteTag = bodyStrings[i];
        //replace/trim removes the sup tags around the string and the white space from the tag
        if (formatIdTag(bodyFootnoteTag) === formatIdTag(indexFootnoteTag)) {
          footnoteMatch = true;
          bodyUniqueId = formatIdTag(bodyFootnoteTag) + '-' + generateRandomId();

          //adds the href and the customAttribute and id
          let linkText = '<a href="#">';
          let newBodyFootnoteTag = linkText + bodyFootnoteTag + '</a>';
          newBodyFootnoteTag = addCustomAttribute(newBodyFootnoteTag, footnoteIdTag);
          newBodyFootnoteTag = addIdToDiv(newBodyFootnoteTag, bodyUniqueId);

          // Use regular expression with global flag for global search and replace
          let escapedBodyFootnoteTag = escapeRegExp(bodyFootnoteTag);
          let regex = new RegExp(escapedBodyFootnoteTag, 'g');
          newBodyText = newBodyText?.replace(regex, newBodyFootnoteTag);
        }
      }

      // Process the footnote tag
      if (footnoteMatch) {
        //creating return button
        let linkText = '<a href="#">';
        let returnButton = '↩';
        let returnButtonTag = linkText + returnButton + '</a>';

        returnButtonTag = addCustomAttribute(returnButtonTag, bodyUniqueId);

        let newIndexFootnoteTag = addIdToDiv(indexFootnoteTag, footnoteIdTag);

        newFootnoteText = newFootnoteText?.replace(indexFootnoteTag, newIndexFootnoteTag);
        newFootnoteText = insertSecondaryString(newFootnoteText, newIndexFootnoteTag, returnButtonTag);
      }
    }
    tempContainer.querySelector('.advancedFootnotesBody').outerHTML = newBodyText;
    tempContainer.querySelector('.advancedFootnotesWrapper').outerHTML = newFootnoteText;
    return tempContainer.innerHTML;
  }

  let chapterBodyContainer = document.createElement('div');
  chapterBodyContainer.innerHTML = htmlString;

  //isolate the tables within the chapter body
  let tableArray = chapterBodyContainer.querySelectorAll('.tableOverflowWrapper');
  let newTableArray = [];
  let tableIdArray = [];

  // Iterate through each entry in the array
  for (let i = 0; i < tableArray.length; i++) {
    // Run the function with the entry and save the returned value back into the array
    newTableArray.push(processFootnoteHtmlString(tableArray[i].innerHTML, false));
    let uniqueId = 'advanced-footnote-table' + i;

    //replace them with a unique id
    let unqiueIdContainer = document.createElement('div');
    unqiueIdContainer.innerHTML = uniqueId;
    unqiueIdContainer.setAttribute('id', uniqueId);

    tableArray[i].innerHTML = unqiueIdContainer.outerHTML;
    tableIdArray.push(unqiueIdContainer);
  }
  //create proper div structure
  if (footnote != null && footnote != '<p><br></p>') {
    const footnoteContainer = document.createElement('div');
    footnoteContainer.innerHTML = footnote;

    chapterBodyContainer.classList.add('advancedFootnotesBody');
    footnoteContainer.classList.add('advancedFootnotesWrapper');

    let outerContainer = document.createElement('div');

    outerContainer.appendChild(chapterBodyContainer);
    outerContainer.appendChild(footnoteContainer);
    chapterBodyContainer = outerContainer;

    chapterBodyContainer.innerHTML = processFootnoteHtmlString(chapterBodyContainer.innerHTML, true);
  }

  // Iterate through each entry in the array, replacing them with the new version
  for (let i = 0; i < tableIdArray.length; i++) {
    chapterBodyContainer.querySelector('#' + tableIdArray[i].id).outerHTML = newTableArray[i];
  }
  return chapterBodyContainer.innerHTML;
};

export { processArticleContent, processContentItem, processPageFootnoteContent, replaceTagsInHtmlString, processAdvancedFootnotes };
