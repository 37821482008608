import React, { Suspense, useEffect, useState } from 'react';
import { hotjar } from 'react-hotjar';
import { useRouteLoaderData, Await, useParams } from 'react-router-dom';
import { AnnualReportBanner, PageNavigation, PreviewPDFControl, RelevantLinks, SideNavigation, Tag } from 'Components';
import { ERROR_PAGE, IS_PREVIEW_MODE, PAGE_NOT_FOUND_PAGE, PATHS, TAG_STYLE_ENUMS, URL_PARAMS } from 'Constants';
import { findPrevNextPublicationNavigationItems } from 'Utils';
import DefaultCoverImage from './DefaultCoverImage';
import style from './AnnualReportCover.module.scss';
import { AnnualReportCoverLoading } from 'Pages';
import { ERROR_CONTENT, PAGE_NOT_FOUND } from 'Constants/StaticContents';
import { STATUS_TYPES } from 'Utils/Error';

/**
 * AnnualReportCover.jsx
 *
 * @summary This component is page view for annual report cover page.
 */
export default function AnnualReportCover() {
  const { base } = useRouteLoaderData(PATHS.ANNUAL_REPORT.ID);
  const [coverImageError, setCoverImageError] = useState(false);
  const [printReady, setPrintReady] = useState(false);
  const urlParams = useParams();

  const handleImageLoadError = () => {
    setCoverImageError(true);
  };

  return (
    // use Suspense to show loading page before `annualReport` finishes loading
    <Suspense fallback={<AnnualReportCoverLoading />}>
      <Await resolve={base} errorElement={ERROR_PAGE()}>
        {(loadedAnnualReport) => {
          // if AR was not found
          if (loadedAnnualReport.isError) {
            if (loadedAnnualReport.statusCode === STATUS_TYPES.NOT_FOUND) return PAGE_NOT_FOUND_PAGE(ERROR_CONTENT.NOT_FOUND);
            if (loadedAnnualReport.statusCode === STATUS_TYPES.RANGE_ERROR) return ERROR_PAGE(PAGE_NOT_FOUND(ERROR_CONTENT.INVALID_CONTENT));
            if (loadedAnnualReport.statusCode === STATUS_TYPES.OTHER) return ERROR_PAGE();
            return ERROR_PAGE();
          }

          const { banner, coverImageSrc, department, filteredPublications, logo, portfolio, relevantLinks, reportingPeriod, routes, routeTitle, system } = loadedAnnualReport;
          const currentPublicationUrl = urlParams[URL_PARAMS.ANNUAL_REPORT];

          // Get page publications navigation link data. Publication data is only filtered to those within its collection (done in the loader.js)
          const navigationData = findPrevNextPublicationNavigationItems(filteredPublications, currentPublicationUrl);
          const tagFullText = system.type.replace(/_/g, ' ');
          const tagText = tagFullText
            .split(' ')
            .map((text) => text[0].toUpperCase())
            .join('');

          document.title = routeTitle;

          // cover image will show default cover image if no image is found
          const coverImage = () => {
            return (
              <div className={style.coverImage}>
                {coverImageSrc && !coverImageError ? (
                  <img className={style.coverImage} src={coverImageSrc} alt={`${routeTitle} cover image`} onLoad={() => setPrintReady(true)} onError={handleImageLoadError} />
                ) : (
                  <DefaultCoverImage entityName={department.name} reportTitle={routeTitle} />
                )}
                {printReady && <span id="readyForPrint" hidden key={new Date().getTime()}></span>}
              </div>
            );
          };

          useEffect(() => {
            // FIXME: HOTJAR INTEGRATION - After MVP 2 release, at the start of MVP3, hotjar need to be removed as this was only for research purpose by designer between MVP2 and MVP3
            hotjar.initialize(process.env.REACT_APP_HOTJAR_ID);
          }, []);

          return (
            <>
              <AnnualReportBanner department={department} portfolio={portfolio} bannerImgSrc={banner} logoImgSrc={logo.url} logoImgAltText={logo.altText} />

              <h1 id={style.reportTitle}>
                <span>
                  {department?.name && <span id={style.departmentName}>{department.name}</span>}
                  {<Tag displayText={tagText} fullText={tagFullText} type={TAG_STYLE_ENUMS.HIGHLIGHT} />}
                  {reportingPeriod && <Tag displayText={reportingPeriod} type={TAG_STYLE_ENUMS.GREY} />}
                </span>
                {routeTitle || <span className="noContentOutlineInside"></span>}
              </h1>

              <div id={style.annualReportCover}>
                <div>
                  {IS_PREVIEW_MODE && <PreviewPDFControl annualReportCodename={system.codename} pdfFileName={routeTitle || null} />}
                  <SideNavigation menus={routes} desktopLookInMobile />
                  <PageNavigation navigationData={navigationData} />
                </div>

                {coverImage()}
              </div>

              <RelevantLinks entity={relevantLinks.entity} corporatePlan={relevantLinks.corporatePlan} portfolio={relevantLinks.portfolio} />
            </>
          );
        }}
      </Await>
    </Suspense>
  );
}
