import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { CONSTANTS, IS_FOR_EXPORT_PDF, IS_PREVIEW_MODE } from 'Constants';
import { getCall } from 'Services';
import { debounce } from 'Utils';
import style from './PreviewPDFControl.module.scss';

/**
 * PreviewPDFControl.jsx
 *
 * @summary Preview only, annual report generate/download PDF control. Only render this component in preview app.
 *
 * @param {Object} props - Component props.
 * @prop {String} annualReportCodename - Annual report codename to generate/get PDF.
 * @prop {String} [pdfFileName] - If provided use this as downloaded PDF file name, else use default file name.
 */
function PreviewPDFControl({ annualReportCodename, pdfFileName }) {
  if (!IS_PREVIEW_MODE || IS_FOR_EXPORT_PDF) {
    return null;
  }

  const [pdfExists, setPdfExists] = useState(null);
  const [waitingPDF, setWaitingPDF] = useState(true);
  const [downloadingPDF, setDownloadingPDF] = useState(false);
  const [generatingPDF, setGeneratingPDF] = useState(false);

  const isProd = process.env?.REACT_APP_ENVIRONMENT?.toLowerCase() === CONSTANTS.ENV.PROD?.toLowerCase();

  const baseURL = isProd ? 'https://apps.transparency.gov.au/' : `https://${process.env?.REACT_APP_ENVIRONMENT?.toLowerCase()}-dart-be.dxau.digital`;

  const getPdfEndpoint = `${baseURL}/api/pdf/GetPdf?annualreportcodename=${annualReportCodename}`;
  const generatePdfEndpoint = `${baseURL}/api/pdf/generatepdf?annualreportcodename=${annualReportCodename}`;
  const checkPdfStatusEndpoint = `${baseURL}/api/pdf/checkpdfstatus?annualreportcodename=${annualReportCodename}`;

  useEffect(() => {
    // on initial render, check if PDF exists or not
    getCall(checkPdfStatusEndpoint, undefined, true, true, false)
      .then((res) => {
        if (res) {
          if (res?.status === 202) {
            // if PDF generation is in progress
            setGeneratingPDF(true);
            checkPdfStatus();
          } else {
            setGeneratingPDF(false);
            setPdfExists(true);
            setWaitingPDF(false);
          }
        }
      })
      .catch((err) => {
        if ([400, 500].includes(err?.response?.status)) {
          alert('Something went wrong. We cannot check if PDF already exists or not. You may try generate PDF again.');
        }

        // if error status is 404, meaning pdf doesn't exists
        setPdfExists(false);
        setWaitingPDF(false);
        setGeneratingPDF(false);
      });
  }, []);

  const checkPdfStatus = debounce(() => {
    getCall(checkPdfStatusEndpoint, undefined, true, true)
      .then((res) => {
        if (res) {
          if (res?.status === 202) {
            // if PDF generation is in progress
            setGeneratingPDF(true);
            checkPdfStatus();
          } else {
            // file exists
            setGeneratingPDF(false);
            setWaitingPDF(false);
            setPdfExists(true);
          }
        } else {
          // Consider PDF is not ready - response is undefined
          checkPdfStatus();
        }
      })
      .catch((err) => {
        if ([400, 500].includes(err?.response?.status)) {
          alert('Something went wrong. We cannot check if PDF already exists or not. You may try generate PDF.');
          setWaitingPDF(false);
          setPdfExists(false);
          setGeneratingPDF(false);
        } else {
          // PDF is not ready yet (if error status is 404)
          setWaitingPDF(true);

          if (err?.response?.status === 404) {
            setPdfExists(false);
            setWaitingPDF(false);
            setGeneratingPDF(false);
          }

          checkPdfStatus();
        }
      });
  }, 10 * 1000);

  const generatePdf = () => {
    setWaitingPDF(true);
    setGeneratingPDF(true);
    // trigger generate pdf api always returns 200.
    getCall(generatePdfEndpoint, undefined, true, true);
    checkPdfStatus();
  };

  const downloadPdf = () => {
    setDownloadingPDF(true);
    getCall(getPdfEndpoint, { responseType: 'blob' }, true)
      .then((res) => {
        // Creating new object of PDF file
        const fileURL = window.URL.createObjectURL(res);
        const link = document.createElement('a');
        link.href = fileURL;
        link.target = '_blank';
        if (pdfFileName) link.download = pdfFileName;
        link.click();
        window.URL.revokeObjectURL(fileURL);
        link.remove();
      })
      .finally(() => {
        setDownloadingPDF(false);
      });
  };

  return (
    <div id={style.previewARcontrolPDF}>
      <button disabled={waitingPDF || downloadingPDF || generatingPDF} onClick={generatePdf}>
        {generatingPDF ? 'Generating PDF' : ''}
        {waitingPDF ? (
          <span className="spinner">
            <span></span>
            <span></span>
            <span></span>
            <span></span>
          </span>
        ) : pdfExists ? (
          'Regenerate PDF'
        ) : (
          'Generate PDF'
        )}
      </button>
      {!generatingPDF && (
        <button disabled={!pdfExists || waitingPDF || downloadingPDF || generatingPDF} title={pdfExists ? 'Download PDF.' : 'Please generate PDF first.'} onClick={downloadPdf}>
          {downloadingPDF ? (
            <span className="spinner">
              <span></span>
              <span></span>
              <span></span>
              <span></span>
            </span>
          ) : (
            'Download PDF'
          )}
        </button>
      )}
    </div>
  );
}

PreviewPDFControl.propTypes = {
  annualReportCodename: PropTypes.string.isRequired,
  pdfFileName: PropTypes.string,
};

export default PreviewPDFControl;
