import React, { Suspense, useEffect, useRef, useState, memo, useCallback } from 'react';
import PropTypes from 'prop-types';
import { renderToString } from 'react-dom/server';
import { hotjar } from 'react-hotjar';
import { useRouteLoaderData, useParams, useLocation, Await } from 'react-router-dom';
import { CSSTransition } from 'react-transition-group';
import { AnnualReportTopPartLoading, AnnualReportContentLoading } from 'Pages';
import {
  CONSTANTS,
  CONTENT_ITEM_TYPES,
  IS_FOR_EXPORT_PDF,
  IS_PREVIEW_MODE,
  IS_PUBLISHED_APP,
  KONTENT_EMPTY_DATA_VALUES,
  PAGE_NOT_FOUND_TITLE,
  PATHS,
  SOMETHING_WENT_WRONG,
} from 'Constants';
import { ERROR_CONTENT } from 'Constants/StaticContents';
import { Loading, LoadingMenu, AnnualReportBanner, PageNavigation, PdfViewer, PreviewPDFControl, RelevantLinks, SideNavigation } from 'Components';
import CompareFunction from 'Components/CompareFunction/CompareFunction';
import { findPrevNextArticleNavigationItems, innerPageScroll, parseRichTextHtml, processPageFootnoteContent, titleToUrl, processAdvancedFootnotes } from 'Utils';
import { STATUS_TYPES } from 'Utils/Error';
import { getAContentItem } from 'Services/Delivery.js';
import icons from 'Assets/svgSprite.svg';
import style from './AnnualReportArticle.module.scss';

let title = 'Transparency Portal'; // default title on load while data is fetching
let errorTitle;
let errorContent;

const errorContentNode = () => {
  return (
    <article>
      <h1 className={style.sectionTitle}>{errorTitle || 'Something went wrong!'}</h1>
      <div className={style.articleContent}>{errorContent || SOMETHING_WENT_WRONG}</div>
    </article>
  );
};

/**
 * AnnualReportArticle.jsx
 *
 * @summary This component is page view for annual report article page.
 */
export default function AnnualReportArticle() {
  const { pathname } = useLocation();
  const { annualReport, base } = useRouteLoaderData(IS_FOR_EXPORT_PDF ? 'pdf' : PATHS.ANNUAL_REPORT.ID);
  const backgroundModalRef = useRef();
  const [openCompareFunction, setOpenCompareFunction] = useState(false);
  const loadedDataAnnualReport = useCallback(Promise.resolve(annualReport), [annualReport]);
  const loadedDataBase = useCallback(Promise.resolve(base), [base]);
  const loadedData = useCallback(Promise.all([loadedDataAnnualReport, loadedDataBase]), [loadedDataAnnualReport, loadedDataBase]);

  // update title
  useEffect(() => {
    document.title = title;
  }, [pathname, title]);

  useEffect(() => {
    if (openCompareFunction) {
      // hide scrollbar of entire page when compare function modal is open
      document.body.style.overflow = 'hidden';
    } else {
      document.body.style.overflow = '';
    }
  }, [openCompareFunction]);

  useEffect(() => {
    const close = (e) => {
      // listen for escape key pressed
      if (e.key === CONSTANTS.ESCAPE_KEY) {
        closeModal();
      }
    };
    window.addEventListener('keydown', close);
    return () => window.removeEventListener('keydown', close);
  });

  const closeModal = () => {
    setOpenCompareFunction(false);
  };

  const backgroundModalClicked = (event) => {
    if (event?.target?.id === style.compareModal) {
      closeModal();
    }
  };

  return (
    <>
      {!IS_FOR_EXPORT_PDF && (
        <Suspense fallback={<AnnualReportTopPartLoading isARPage={true} />}>
          <Await resolve={loadedDataBase}>
            {(loadedBase) => {
              const { banner, department, logo, portfolio } = loadedBase;

              return <AnnualReportBanner department={department} portfolio={portfolio} bannerImgSrc={banner} logoImgSrc={logo?.url} logoImgAltText={logo?.altText} />;
            }}
          </Await>
        </Suspense>
      )}

      <div id="annualReportHolder">
        <div>
          {/* Side navigation */}
          <Suspense fallback={<LoadingMenu />}>
            <Await resolve={loadedDataBase}>
              {(loadedBase) => {
                const { routeBaseUrl, routes, routeTitle, system } = loadedBase;

                return (
                  <>
                    {IS_PREVIEW_MODE && <PreviewPDFControl annualReportCodename={system?.codename} pdfFileName={routeTitle || null} />}
                    {!IS_FOR_EXPORT_PDF && <SideNavigation menus={routes} cover={{ title: routeTitle, url: routeBaseUrl }} />}
                  </>
                );
              }}
            </Await>
          </Suspense>
        </div>

        {/* Report content area */}
        <div className={style.articleColumn}>
          <Suspense fallback={<AnnualReportContentLoading />}>
            <Await resolve={loadedData} errorElement={errorContentNode()}>
              {(loadedContent) => {
                const { extraObjToParser, processedData, routeBaseUrl, routes, system, statusCode, isError, generatePdfBatchImageLoadNumber } = loadedContent[0];

                return (
                  <ArticleBody
                    extraObjToParser={extraObjToParser}
                    processedData={processedData}
                    routeBaseUrl={routeBaseUrl}
                    routes={routes}
                    system={system}
                    statusCode={statusCode}
                    isError={isError}
                    generatePdfBatchImageLoadNumber={generatePdfBatchImageLoadNumber}
                  />
                );
              }}
            </Await>
          </Suspense>
        </div>
      </div>

      {/* for none pdf env only, add relevant links*/}
      {!IS_FOR_EXPORT_PDF && (
        <Suspense>
          <Await resolve={loadedDataBase}>
            {(loadedBase) => {
              const { relevantLinks } = loadedBase;

              return <RelevantLinks entity={relevantLinks.entity} corporatePlan={relevantLinks.corporatePlan} portfolio={relevantLinks.portfolio} />;
            }}
          </Await>
        </Suspense>
      )}

      {/* for none pdf env only, add compare AR function */}
      {!IS_FOR_EXPORT_PDF && (
        <Suspense>
          <Await resolve={loadedDataAnnualReport}>
            {(loadedBase) => {
              const { department, mandatoryRequirementsContentItem, performanceData, reportingPeriod, ruleList } = loadedBase;

              const compareFunctionARDetails = {
                entity: department?.name || '',
                entityUrl: department?.webUrl,
                bodyType: department?.bodyType || '',
                reportingYear: reportingPeriod || '',
                statusText: department?.statusText || '',
              };

              return (
                <>
                  {Object.keys(department || {}).length > 0 && (
                    <button className="compareButton" onClick={() => setOpenCompareFunction(true)}>
                      <span>Compare Annual Reports</span>
                      <svg>
                        <use href={`${icons}#compare`} />
                      </svg>
                    </button>
                  )}

                  <CSSTransition
                    nodeRef={backgroundModalRef}
                    in={openCompareFunction}
                    classNames={{
                      enter: 'fadeIn',
                      exit: 'fadeOut',
                    }}
                    timeout={{ enter: 300, exit: 300 }}
                    unmountOnExit>
                    <div id={style.compareModal} ref={backgroundModalRef} onClick={backgroundModalClicked}>
                      <CompareFunction
                        mandatoryRules={{ ruleList, performanceData, mandatoryRequirementsContentItem }}
                        annualReportDetails={compareFunctionARDetails}
                        closeModalFunction={closeModal}
                        modalIsOpen={openCompareFunction}></CompareFunction>
                    </div>
                  </CSSTransition>
                </>
              );
            }}
          </Await>
        </Suspense>
      )}
    </>
  );
}

/**
 * ArticleBody
 *
 * @summary Render annual report body.
 */
const ArticleBody = memo(function ArticleBody({ extraObjToParser, processedData, routeBaseUrl, routes, system, statusCode, isError, generatePdfBatchImageLoadNumber }) {
  const params = useParams();
  const { pathname, hash } = useLocation();
  const [contentLoading, setContentLoading] = useState(false);
  const [printReady, setPrintReady] = useState(false);
  const [pdfPrintReady, setPdfPrintReady] = useState(false);

  const sectionParam = params[PATHS.ANNUAL_REPORT.CHILDREN.REPORT_SECTION];
  const subSectionParam = params[PATHS.ANNUAL_REPORT.CHILDREN.REPORT_SUB_SECTION];
  const currentChapterDataIndex = routes?.findIndex((obj) => obj.path === `${routeBaseUrl}/${titleToUrl(sectionParam)}`);

  let currentContent = routes?.[currentChapterDataIndex];
  let currentProcessedData = processedData?.[currentChapterDataIndex];
  let pageNavigation = {
    prevTitle: null,
    prevPath: null,
    nextTitle: null,
    nextPath: null,
  };

  // check if current page is section of a chapter
  if (subSectionParam) {
    const sectionIndex = currentContent?.children?.findIndex((obj) => obj.path === pathname);
    currentContent = currentContent?.children[sectionIndex];
    currentProcessedData = currentProcessedData?.sections?.[sectionIndex];
  }

  useEffect(() => {
    // this is to support BE export pdf letting server page is fully loaded including images
    setPrintReady(false);

    if (IS_PUBLISHED_APP && currentContent) {
      const currentContentCodename = currentContent?.contentCodename;
      const needToFetchData = !currentContent.fetched && !currentContent.fetching && currentContentCodename !== undefined;
      const fetchingOrfetchedAndNotProcessed = currentContent.fetching || (currentContent.fetched && !currentContent.fetching && !currentProcessedData.bodyDataProcessed);
      const noCodenameAndNotProcessed = currentContentCodename === undefined && !currentProcessedData.bodyDataProcessed;

      if (needToFetchData || fetchingOrfetchedAndNotProcessed || noCodenameAndNotProcessed) {
        setContentLoading(true);
      }

      // only fetch content if it is not feched and currently not fetching and have codename
      if (needToFetchData) {
        currentContent.fetching = true;
        getAContentItem(
          extraObjToParser.articleWebUrl,
          CONTENT_ITEM_TYPES.ANNUAL_REPORT.ID,
          false,
          {
            'system.collection': system?.collection,
            chapter: currentContentCodename,
          },
          true,
        )
          .then((res) => {
            if (res?.Footnotes?.length > 0) {
              const withPageFootnote = processPageFootnoteContent(res?.Footnotes, res?.Body, extraObjToParser);
              res.Body = withPageFootnote?.updatedPageHtmlString || res.Body;
              currentProcessedData.pageFootnoteNode = withPageFootnote?.pageFootnoteNode;
              //report chapter
            } else if (res?.System.type == 'report_chapter' && res?.Footnotes8b896b4 != null && res?.Footnotes8b896b4 != '<p><br></p>') {
              res.Body = processAdvancedFootnotes(res.Body, res.Footnotes8b896b4);
            }
            //report section
            else if (res?.System.type == 'report_sections' && res?.Footnotes3fb0a4f != null && res?.Footnotes3fb0a4f != '<p><br></p>') {
              res.Body = processAdvancedFootnotes(res.Body, res.Footnotes3fb0a4f);
            } //no footnote
            else if (res.Body?.includes('<div class="advancedFootnotesWrapper">') && res.Body?.includes('<div class="advancedFootnotesBody">')) {
              res.Body = processAdvancedFootnotes(res.Body);
            }

            Promise.resolve(parseRichTextHtml(res?.Body, extraObjToParser, undefined, false)).then((res) => {
              currentProcessedData.bodyNode = res;
              currentProcessedData.bodyDataProcessed = true;
              currentContent.fetched = true;
              currentContent.fetching = false;
              setTimeout(() => {
                setContentLoading(false);
              }, 500);
            });
          })
          .catch((err) => {
            console.error(err);
            currentProcessedData.bodyNode = SOMETHING_WENT_WRONG;
            currentProcessedData.bodyDataProcessed = false;
            currentContent.fetched = false;
            currentContent.fetching = false;
            setTimeout(() => {
              setContentLoading(false);
            }, 500);
          });
      } else if (fetchingOrfetchedAndNotProcessed) {
        // if content is currently fetching, wait till it is fetched and process body node
        const waitAndProcessBodyNode = () => {
          setContentLoading(true);
          if (currentContent.fetched && !currentContent.fetching && !currentProcessedData.bodyDataProcessed) {
            if (currentProcessedData?.pageFootnoteNode?.length > 0) {
              const withPageFootnote = processPageFootnoteContent(currentProcessedData?.pageFootnoteNode, currentProcessedData.bodyNode, extraObjToParser);
              currentProcessedData.bodyNode = withPageFootnote?.updatedPageHtmlString || currentProcessedData.bodyNode;
              currentProcessedData.pageFootnoteNode = withPageFootnote?.pageFootnoteNode;
            }

            Promise.resolve(parseRichTextHtml(currentProcessedData.preLoadedContent || currentProcessedData.bodyNode, extraObjToParser, undefined, false)).then((res) => {
              currentProcessedData.bodyNode = res;
              currentProcessedData.bodyDataProcessed = true;
              setTimeout(() => {
                setContentLoading(false);
              }, 500);
            });
          } else if (!currentContent.fetched && currentContent.fetching) {
            setTimeout(() => {
              waitAndProcessBodyNode();
            }, 100);
          } else {
            setContentLoading(true);
            setTimeout(() => {
              setContentLoading(false);
            }, 500);
          }
        };

        waitAndProcessBodyNode();
      } else {
        if (noCodenameAndNotProcessed) {
          // if codename is undefined, consider it's FE auto added LoR and just process data
          Promise.resolve(parseRichTextHtml(currentProcessedData.bodyNode, extraObjToParser, undefined, false)).then((res) => {
            currentProcessedData.bodyNode = res;
            currentProcessedData.bodyDataProcessed = true;
            setTimeout(() => {
              setContentLoading(false);
            }, 500);
          });
        } else {
          setTimeout(() => {
            setContentLoading(false);
          }, 500);
        }
      }
    }
  }, [pathname]);

  useEffect(() => {
    autoScroll();
  }, [pathname, hash]);

  useEffect(() => {
    if (!contentLoading) {
      autoScroll();
    }
  }, [contentLoading]);

  const fetchAndWaitForChapters = async (routes) => {
    // start fetching chapter by chapter, section by section in the background without processing data. processing data will only happen when user actually visit the chapter/section.
    const fetchContent = async (codename, chapterIndex = 0, sectionIndex) => {
      if (codename) {
        let targetProcessedData = processedData?.[chapterIndex];
        let targetRouteObj = routes[chapterIndex];

        // have to check if it's not undefined as `sectionIndex` can be `0` as it's index number and in JS 0 is falsy.
        if (sectionIndex !== undefined) {
          targetProcessedData = targetProcessedData?.sections?.[sectionIndex];
          targetRouteObj = targetRouteObj?.children?.[sectionIndex];
        }

        if (!targetRouteObj.fetched && !targetRouteObj.fetching) {
          targetRouteObj.fetching = true;
          await getAContentItem(
            extraObjToParser.articleWebUrl,
            CONTENT_ITEM_TYPES.ANNUAL_REPORT.ID,
            false,
            {
              'system.collection': system?.collection,
              chapter: codename,
            },
            true,
          )
            .then((res) => {
              targetProcessedData.bodyNode = KONTENT_EMPTY_DATA_VALUES.includes(res?.Body) ? '' : res?.Body;
              if (res?.Footnotes && res?.Footnotes?.length > 0) {
                const withPageFootnote = processPageFootnoteContent(res?.Footnotes, res?.Body, extraObjToParser);
                res.Body = withPageFootnote?.updatedPageHtmlString || res.Body;
                currentProcessedData.pageFootnoteNode = withPageFootnote?.pageFootnoteNode;
                // report chapter
              } else if (res?.System.type == 'report_chapter' && res?.Footnotes8b896b4 != null && res?.Footnotes8b896b4 != '<p><br></p>') {
                targetProcessedData.bodyNode = processAdvancedFootnotes(res.Body, res.Footnotes8b896b4);
              }
              //report section
              else if (res?.System.type == 'report_sections' && res?.Footnotes3fb0a4f != null && res?.Footnotes3fb0a4f != '<p><br></p>') {
                targetProcessedData.bodyNode = processAdvancedFootnotes(res.Body, res.Footnotes3fb0a4f);
              }
              //no footnote
              else if (
                targetProcessedData.bodyNode?.includes('<div class="advancedFootnotesWrapper">') &&
                targetProcessedData.bodyNode?.includes('<div class="advancedFootnotesBody">')
              ) {
                targetProcessedData.bodyNode = processAdvancedFootnotes(targetProcessedData.bodyNode);
              } else {
                targetProcessedData.pageFootnoteNode = null;
              }

              targetProcessedData.bodyDataProcessed = false;
              targetRouteObj.fetched = true;
              targetRouteObj.fetching = false;
            })
            .catch((err) => {
              targetRouteObj.fetched = false;
              targetRouteObj.fetching = false;
              console.error(`Could not fetch ${sectionIndex ? 'section' : 'chapter'} with \`${codename}\`.`, err);
            });
        }
      }
    };

    for (let chapterObjIndex = 0; chapterObjIndex < routes.length; chapterObjIndex++) {
      const chapterObj = routes[chapterObjIndex];
      const chapterCodename = chapterObj?.contentCodename;
      await fetchContent(chapterCodename, chapterObjIndex);

      // if chapter has sections, fetch given chapter's sections in order
      if (chapterObj?.children) {
        for (let sectionObjIndex = 0; sectionObjIndex < chapterObj.children.length; sectionObjIndex++) {
          const sectionObj = chapterObj.children[sectionObjIndex];
          const sectionCodename = sectionObj?.contentCodename;
          await fetchContent(sectionCodename, chapterObjIndex, sectionObjIndex);
        }
      }
    }
  };

  // only triggered once on initial load
  useEffect(() => {
    // FIXME: HOTJAR INTEGRATION - After MVP 2 release, at the start of MVP3, hotjar need to be removed as this was only for research purpose by designer between MVP2 and MVP3
    hotjar.initialize(process.env.REACT_APP_HOTJAR_ID);

    if (IS_PUBLISHED_APP) {
      fetchAndWaitForChapters(routes);
    }
  }, []);

  useEffect(() => {
    // this is to support BE export pdf letting server page is fully loaded including images
    if (IS_FOR_EXPORT_PDF) {
      // batch load 5 images at a time and next batch starts load when first 5 are fully loaded
      /** NOTE: instead traditional lazy loading, which only loads image only if image is within visible viewport, "lazy load" images
       * by swaping placeholder image element/component tag to actual image component that loads image file as in BE chrome driver, it's not
       * guarenteed that it can scroll to trigger lazy loading */
      const imagesToLoad = Array.from(document.querySelectorAll('object[data-imageurl]')).filter(Boolean);
      const arrayChunk = [];
      const chunkSize = generatePdfBatchImageLoadNumber || 10;
      let toLoadImageChunkIndex = 0;

      for (let i = 0; i < imagesToLoad.length; i += chunkSize) {
        const chunk = imagesToLoad.slice(i, i + chunkSize);
        arrayChunk.push({ images: chunk, startLoad: i === toLoadImageChunkIndex, loadCompleted: false });
      }

      const swapToImageComponent = async (arrayChunkIndex) => {
        const targetChunk = arrayChunk?.[arrayChunkIndex];

        if (targetChunk) {
          targetChunk?.images?.forEach((tag) => {
            tag.outerHTML = renderToString(parseRichTextHtml(tag.outerHTML, undefined, false, false));
          });

          Promise.all(
            Array.from(document.images)
              .filter((img) => !img.complete)
              .map(
                (img) =>
                  new Promise((resolve) => {
                    img.onload = img.onerror = resolve;
                  }),
              ),
          ).then(() => {
            targetChunk.loadCompleted = true;

            if (arrayChunk?.every((obj) => obj.loadCompleted === true)) {
              setPrintReady(true);
            } else {
              toLoadImageChunkIndex++;
              swapToImageComponent(toLoadImageChunkIndex);
            }
          });
        }
      };

      swapToImageComponent(toLoadImageChunkIndex);
    } else {
      // make sure no placeholder image tag is left unchanged to image component
      Array.from(document.querySelectorAll('object[data-imageurl]'))
        ?.filter(Boolean)
        ?.forEach((tag) => {
          tag.outerHTML = renderToString(parseRichTextHtml(tag.outerHTML, undefined, false, false));
        });
    }
  });

  const autoScroll = () => {
    const scrollTargetId = hash?.slice(1); // ignore index 0 as it would always `#`
    const scrollTarget = document.getElementById(scrollTargetId);

    // if scroll target exists
    if (scrollTargetId && scrollTarget) {
      innerPageScroll(scrollTargetId);
    } else if (window.scrollY !== 0) {
      window.scrollTo({ top: 0, behavior: 'smooth' });
    }
  };

  const generateSectionContent = () => {
    // if subSection param is NOT defined, meaning viewer is on main "chapter" page
    if (!subSectionParam) {
      pageNavigation = findPrevNextArticleNavigationItems(routes, currentChapterDataIndex, false, null);

      return <ArticleContent isChapter={true} contentLoading={contentLoading} data={currentProcessedData} extraObjToParser={extraObjToParser} />;
    }

    // if subSection param is defined, only render given "section"
    const sectionIndex = routes?.[currentChapterDataIndex]?.children?.findIndex((obj) => obj.path === pathname);

    pageNavigation = findPrevNextArticleNavigationItems(routes, currentChapterDataIndex, true, sectionIndex);
    return <ArticleContent isChapter={false} contentLoading={contentLoading} data={currentProcessedData} extraObjToParser={extraObjToParser} />;
  };

  const generateContentsForPDF = () => {
    return (
      <>
        {processedData?.map((chapter, chapterIndex) => {
          const chapterKey = `chapter - ${chapter?.title} - ${chapterIndex}`;
          const articleContentClass = [style.articleContent];
          const pdfItem = chapter?.[CONTENT_ITEM_TYPES.PDF.ID]?.value?.[0];
          const sections = chapter?.sections;
          const chapterIsANAO = chapter?.system?.type === CONTENT_ITEM_TYPES.ANAO_REPORT_SECTION.ID;

          /**
           * NOTE: As BE request, for ANAO report section only, system id (invisible for pdf export) appears right before where PDF would render, else render before <h1>
           */

          return (
            <div key={chapterKey} className={style.chapterHolder}>
              <span className="invisibleForPdfExport" aria-hidden>
                {chapter?.system?.id}
              </span>
              <h1 className={style.chapterTitle}>{chapter?.title}</h1>
              <div className={articleContentClass.join(' ')}>
                {chapter?.bodyNode && <section>{chapter.bodyNode}</section>}
                {chapterIsANAO && (
                  <span className="invisibleForPdfExport breakPage" aria-hidden>
                    {`${chapter?.system?.id}_pdf`}
                  </span>
                )}
                {pdfItem?.url && pdfItem?.type?.toLowerCase() === 'application/pdf' && (
                  <PdfViewer pdfSrc={pdfItem?.url} fileName={pdfItem?.name} setPdfPrintReady={setPdfPrintReady} />
                )}
                {chapter?.pageFootnoteNode && <footer className={style.pageFootnoteWrapper}>{chapter.pageFootnoteNode}</footer>}
              </div>
              {sections?.length > 0 &&
                sections?.map((section, sectionIndex) => {
                  const sectionKey = `section - ${section?.title} - ${sectionIndex}`;
                  const pdfItem = section?.[CONTENT_ITEM_TYPES.PDF.ID]?.value?.[0];
                  const sectionIsANAO = section?.system?.type === CONTENT_ITEM_TYPES.ANAO_REPORT_SECTION.ID;

                  return (
                    <div key={sectionKey} className={style.sectionHolder}>
                      <span className="invisibleForPdfExport" aria-hidden>
                        {section?.system?.id}
                      </span>
                      <h1 className={style.sectionTitle}>{section?.title}</h1>
                      <div className={articleContentClass.join(' ')}>
                        {section?.bodyNode && <section>{section.bodyNode}</section>}
                        {sectionIsANAO && (
                          <span className="invisibleForPdfExport breakPage" aria-hidden>
                            {`${section?.system?.id}_pdf`}
                          </span>
                        )}
                        {pdfItem?.url && pdfItem?.type?.toLowerCase() === 'application/pdf' && <PdfViewer pdfSrc={pdfItem?.url} fileName={pdfItem?.name} />}
                        {section?.pageFootnoteNode && <footer className={style.pageFootnoteWrapper}>{section.pageFootnoteNode}</footer>}
                      </div>
                    </div>
                  );
                })}
            </div>
          );
        })}
      </>
    );
  };

  // if AR was not found
  if (isError) {
    switch (statusCode) {
      case STATUS_TYPES.NOT_FOUND:
        errorContent = ERROR_CONTENT.NOT_FOUND;
        errorTitle = PAGE_NOT_FOUND_TITLE;
        break;

      case STATUS_TYPES.RANGE_ERROR:
        errorContent = ERROR_CONTENT.INVALID_CONTENT;
        break;

      default:
        break;
    }

    return errorContentNode();
  }

  // Show 'Page not found' if chapter or child section entered in url is not found
  if (!IS_FOR_EXPORT_PDF) {
    if (currentChapterDataIndex === -1) {
      errorContent = ERROR_CONTENT.NOT_FOUND_CHAPTER;
      errorTitle = PAGE_NOT_FOUND_TITLE;
      return errorContentNode();
    } else if (
      subSectionParam &&
      routes[currentChapterDataIndex].children.findIndex((obj) => obj.path === `${routeBaseUrl}/${titleToUrl(sectionParam)}/${titleToUrl(subSectionParam)}`) === -1 // checks if subsection is found
    ) {
      errorContent = ERROR_CONTENT.NOT_FOUND_CHAPTER;
      return errorContentNode();
    }
  }

  return (
    <>
      <article>{IS_FOR_EXPORT_PDF ? generateContentsForPDF() : generateSectionContent()}</article>
      <div className={style.pageNavigation}>
        <PageNavigation navigationData={pageNavigation} />
      </div>

      {printReady && pdfPrintReady ? <span id="readyForPrint" hidden key={new Date().getTime()}></span> : null}
    </>
  );
});

ArticleBody.propTypes = {
  extraObjToParser: PropTypes.object.isRequired,
  processedData: PropTypes.array.isRequired,
  routeBaseUrl: PropTypes.string.isRequired,
  routes: PropTypes.array.isRequired,
  system: PropTypes.object,
  statusCode: PropTypes.string,
  isError: PropTypes.bool,
  generatePdfBatchImageLoadNumber: PropTypes.number,
};

/**
 * ArticleContent
 *
 * NOTE: Reason to have seperate this out is to improve performance by reduce rerendering when data to render content are unchanged.
 *
 * @summary Render annual report content.
 *
 * @param {Object} props - Component props.
 * @prop {Boolean} contentLoading - Define if it content data is loading or not.
 * @prop {Object} data - FE processed data of current annual report to be displayed.
 * @prop {Object} extraObjToParser - Extra data to be used for parser.
 * @prop {Boolean} [isChapter=true] - Define if content is chapter or chapter's section.
 */
const ArticleContent = memo(function ArticleContent({ isChapter = true, contentLoading, data, extraObjToParser }) {
  const articleContentClass = [style.articleContent];
  /**
   * Note that in Kontent, as we are using OOTB feature for upload/select pdf asset, it
   * does not validate if uploaded/selected pdf asset is actually pdf type or not. As so,
   * FE will not render if data type is not `application/pdf` even data is available.
   */
  const pdfItem = data?.pdfItem?.[0];

  const title = data?.title ?? '';
  document.title = title;

  // if not published app and body data isn't processed
  if (!IS_PUBLISHED_APP && !data.bodyDataProcessed) {
    data.bodyNode = parseRichTextHtml(data.bodyNode, extraObjToParser, undefined, IS_PREVIEW_MODE ? true : false);
    data.bodyDataProcessed = true;
  }

  const findTableElement = (children) => {
    let tableElement = null;
    React.Children.forEach(children, (child) => {
      if (React.isValidElement(child) && child.type === 'table') {
        tableElement = child;
      } else if (React.isValidElement(child) && child.props.children) {
        // If child has children, recursively search
        const nestedTable = findTableElement(child.props.children);
        if (nestedTable) {
          tableElement = nestedTable;
        }
      }
    });
    return tableElement;
  };

  function processTableElement(tableData) {
    // Function to convert percentage value to pixel
    const convertPercentageToPixel = (value, total) => {
      return (parseFloat(value) / 100) * parseFloat(total) + 'px';
    };

    // Convert height and width values from percentage to pixels
    const convertStylesToPixel = (element) => {
      const children = React.Children.toArray(element?.props?.children);
      const convertedChildren = React.Children.map(children, (child) => {
        if (React.isValidElement(child)) {
          const style = child.props.style || {};
          const width = style.width;
          const height = style.height;
          const parentWidth = element.props.style.width || '100%';
          const parentHeight = element.props.style.height || 'auto';
          const convertedWidth = convertPercentageToPixel(width, parentWidth);
          const convertedHeight = convertPercentageToPixel(height, parentHeight);
          const newStyle = { ...style, width: convertedWidth, height: convertedHeight };
          return React.cloneElement(child, { style: newStyle });
        }
        return child;
      });
      return React.cloneElement(element, {}, convertedChildren);
    };

    // Convert all styles to pixel recursively
    const convertTableStylesToPixel = (element) => {
      const children = React.Children.toArray(element?.props?.children);
      const convertedChildren = React.Children.map(children, (child) => {
        if (React.isValidElement(child)) {
          if (child.type === 'tr') {
            return convertTableStylesToPixel(child);
          } else if (child.type === 'td') {
            return convertStylesToPixel(child);
          }
        }
        return child;
      });
      return React.cloneElement(element, {}, convertedChildren);
    };

    const replaceChild = (parentElement, originalChild, newChild) => {
      // Recursive helper function
      const replaceInChildren = (children) => {
        return React.Children.map(children, (child) => {
          if (React.isValidElement(child)) {
            if (child === originalChild) {
              return newChild;
            } else if (child.props.children) {
              // Recursively traverse nested children
              return React.cloneElement(child, {}, replaceInChildren(child.props.children));
            }
          }
          return child;
        });
      };

      // Main function
      if (Array.isArray(parentElement) && parentElement.every(React.isValidElement)) {
        // If parentElement is an array of React elements
        return replaceInChildren(parentElement);
      } else if (React.isValidElement(parentElement)) {
        // If parentElement is a single React element
        const children = React.Children.toArray(parentElement.props.children);
        const newChildren = replaceInChildren(children);
        return React.cloneElement(parentElement, {}, newChildren);
      } else {
        // Invalid input or no children
        return parentElement;
      }
    };

    let tableElement = findTableElement(tableData);

    if (tableElement) {
      const tableWithPixelStyles = convertTableStylesToPixel(tableElement);
      let returnedTableString = replaceChild(tableData, tableElement, tableWithPixelStyles);
      return returnedTableString;
    }
  }

  let tableElement = findTableElement(data.bodyNode);

  if (tableElement) {
    data.bodyNode = processTableElement(data.bodyNode);
  }

  return (
    <>
      <h1 className={isChapter ? style.chapterTitle : style.sectionTitle}>{title}</h1>
      <div className={articleContentClass.join(' ')}>
        <>
          {data?.bodyNode && (
            <>
              {contentLoading || !data.bodyDataProcessed ? (
                <section>
                  <Loading className={style.contentLoading} />
                </section>
              ) : (
                <>
                  <section>{data.bodyNode}</section>
                  {pdfItem?.url && pdfItem?.type?.toLowerCase() === 'application/pdf' && <PdfViewer pdfSrc={pdfItem?.url} fileName={pdfItem?.name} />}
                  {data?.pageFootnoteNode && <footer className={style.pageFootnoteWrapper}>{data.pageFootnoteNode}</footer>}
                </>
              )}
            </>
          )}
        </>
      </div>
    </>
  );
});

ArticleContent.propTypes = {
  isChapter: PropTypes.bool,
  contentLoading: PropTypes.bool.isRequired,
  data: PropTypes.object.isRequired,
  extraObjToParser: PropTypes.object.isRequired,
};
