import React from 'react';
import { Outlet } from 'react-router-dom';
import App from 'App';
import {
  AnnualReportArticle,
  AnnualReportCover,
  ContributerLandingPage,
  DataSets,
  DataSetsVisualised,
  Dummy,
  FinancialRatios,
  HomePage,
  LoadingAnnualReportPreview,
  PortfolioEntitiesCompanies,
  ProfilePage,
  PublicationsList,
  SearchPage,
  StandardPage,
  TablePreview,
} from 'Pages';
import {
  annualReportLoader,
  convertFilterUrlToApi,
  dataSetsPageLoader,
  financialRatiosPageLoader,
  homepageLoader,
  oldAnnualReportLinkRedirect,
  portfoliosLoader,
  previewAnnualReportRedirector,
  profileLoader,
  publicationsLoader,
  searchLoader,
  standardPageLoader,
  webDataTableLoader,
} from 'Utils';
import { IS_FOR_EXPORT_PDF, IS_PREVIEW_MODE, PAGE_NOT_FOUND_TITLE, URL_QUERY_KEYS } from './Constants';
import { PAGE_NOT_FOUND, SOMETHING_WENT_WRONG } from './StaticContents';
// import { dataSetsPageDefinitionsLoader } from 'Utils/Loaders';

const URL_PARAMS = {
  PORTFOLIO: 'portfolioUrlSlug',
  ENTITY: 'entityUrlSlug',
  ANNUAL_REPORT: 'arUrlSlug',
  CODENAME: 'codename',
  STANDARD_PAGE: 'standardPageUrlSlug',
  YEAR: 'reportingYearUrlSlug',
};

const PATHS = {
  HOME: {
    BASE: '/',
    ID: 'home',
  },
  AUTH: 'auth',
  PAGE_NOT_FOUND: 'page-not-found',
  PORTFOLIOS_ENTITIES_COMPANIES: {
    BASE: 'portfolio-entities-companies',
    ID: 'portfolioEntitiesCompanies',
    TITLE: 'Portfolio, entities and companies',
    CHILDREN: {
      PORTFOLIO: {
        BASE: URL_PARAMS.PORTFOLIO,
        ID: 'portfolioProfile',
      },
      ENTITY: {
        BASE: URL_PARAMS.ENTITY,
        ID: 'entityProfile',
      },
    },
  },
  PUBLICATIONS_LIST: {
    BASE: 'publications',
    ID: 'publications',
    TITLE: 'Publications',
  },
  ANNUAL_REPORT: {
    ID: 'annualReport',
    BASE: `:${URL_PARAMS.PORTFOLIO}/:${URL_PARAMS.ENTITY}`,
    CHILDREN: {
      COVER: URL_PARAMS.ANNUAL_REPORT,
      REPORT_SECTION: 'section',
      REPORT_SUB_SECTION: 'subSection',
    },
  },
  CONTRIBUTER_LANDING_PAGE: 'dart',
  SEARCH: {
    ID: 'search',
    BASE: 'search',
    TITLE: 'Search',
  },
  STANDARD_PAGE: {
    ID: 'standardPage',
    BASE: `:${URL_PARAMS.STANDARD_PAGE}`,
  },
  DATA_SETS: {
    ID: 'dataSets',
    BASE: 'data-sets',
    TITLE: 'Data sets',
    CHILDREN: {
      DATA_SETS_VISUALISED: {
        ID: 'dataSetsVisualised',
        BASE: 'data-sets-visualised',
        TITLE: 'Data set visualisation',
      },
      DATA_DEFINITIONS: {
        ID: 'dataDefinitionsAndSchema',
        BASE: 'data-definitions-and-schema',
        TITLE: 'Data definitions and schema',
      },
    },
  },
  FINANCIAL_RATIOS: {
    ID: 'financialRatios',
    BASE: 'financial-ratios',
    TITLE: 'Financial ratios',
  },
};

const ERROR_PAGE = (content) => {
  return (
    <StandardPage title="Something went wrong!" hideRelevantLinks>
      {content ? content : SOMETHING_WENT_WRONG}
    </StandardPage>
  );
};

const PAGE_NOT_FOUND_PAGE = (content) => <StandardPage title={PAGE_NOT_FOUND_TITLE}>{PAGE_NOT_FOUND(content)}</StandardPage>;

/**
 * crumb obj from route's handle if route should have crumb
 *
 * @param {Boolean} isCrumbNameDynamic - If true, `crumbNameSourceId` must be given, else, `crumbName` must be given.
 * @param {String} [crumbNameSourceId] - Route's ID.
 * @param {String} [crumbName] - Crumb's name.
 */
class Crumb {
  constructor(isCrumbNameDynamic, crumbNameSourceId = undefined, crumbName = undefined) {
    this.isCrumbNameDynamic = isCrumbNameDynamic;
    this.crumbNameSourceId = crumbNameSourceId;
    this.crumbName = crumbName;
  }
}

const ROUTES = [
  {
    path: PATHS.HOME.BASE,
    element: <App />,
    id: PATHS.HOME.ID,
    loader: async () => homepageLoader(),
    shouldRevalidate: () => false,
    children: [
      {
        path: '',
        element: (
          <React.StrictMode>
            <HomePage />
          </React.StrictMode>
        ),
      },
      {
        path: `${PATHS.PORTFOLIOS_ENTITIES_COMPANIES.BASE}`,
        element: (
          <React.StrictMode>
            <Outlet />
          </React.StrictMode>
        ),
        errorElement: ERROR_PAGE(),
        id: PATHS.PORTFOLIOS_ENTITIES_COMPANIES.ID,
        title: PATHS.PORTFOLIOS_ENTITIES_COMPANIES.TITLE,
        shouldRevalidate: () => false,
        loader: async () => await portfoliosLoader(),
        handle: {
          crumb: new Crumb(false, PATHS.PORTFOLIOS_ENTITIES_COMPANIES.ID, PATHS.PORTFOLIOS_ENTITIES_COMPANIES.TITLE),
        },
        children: [
          {
            path: '',
            element: <PortfolioEntitiesCompanies />,
          },
          {
            path: `:${PATHS.PORTFOLIOS_ENTITIES_COMPANIES.CHILDREN.PORTFOLIO.BASE}`,
            element: <Outlet />,
            loader: ({ params }) => profileLoader(PATHS.PORTFOLIOS_ENTITIES_COMPANIES.CHILDREN.PORTFOLIO.ID, params[PATHS.PORTFOLIOS_ENTITIES_COMPANIES.CHILDREN.PORTFOLIO.BASE]),
            shouldRevalidate: (params) => {
              if (
                params?.currentParams?.[PATHS.PORTFOLIOS_ENTITIES_COMPANIES.CHILDREN.PORTFOLIO.BASE] !==
                params?.nextParams?.[PATHS.PORTFOLIOS_ENTITIES_COMPANIES.CHILDREN.PORTFOLIO.BASE]
              ) {
                return true;
              }
              return false;
            },
            id: PATHS.PORTFOLIOS_ENTITIES_COMPANIES.CHILDREN.PORTFOLIO.ID,
            handle: {
              crumb: new Crumb(true, PATHS.PORTFOLIOS_ENTITIES_COMPANIES.CHILDREN.PORTFOLIO.ID),
            },
            children: [
              {
                path: '',
                element: <ProfilePage loaderId={PATHS.PORTFOLIOS_ENTITIES_COMPANIES.CHILDREN.PORTFOLIO.ID} />,
              },
              {
                path: `:${PATHS.PORTFOLIOS_ENTITIES_COMPANIES.CHILDREN.ENTITY.BASE}`,
                id: PATHS.PORTFOLIOS_ENTITIES_COMPANIES.CHILDREN.ENTITY.ID,
                element: <ProfilePage loaderId={PATHS.PORTFOLIOS_ENTITIES_COMPANIES.CHILDREN.ENTITY.ID} isEntity />,
                shouldRevalidate: () => false,
                loader: ({ params }) =>
                  profileLoader(
                    PATHS.PORTFOLIOS_ENTITIES_COMPANIES.CHILDREN.ENTITY.ID,
                    params[PATHS.PORTFOLIOS_ENTITIES_COMPANIES.CHILDREN.ENTITY.BASE],
                    params[PATHS.PORTFOLIOS_ENTITIES_COMPANIES.CHILDREN.PORTFOLIO.BASE],
                  ),
                handle: {
                  crumb: new Crumb(true, PATHS.PORTFOLIOS_ENTITIES_COMPANIES.CHILDREN.ENTITY.ID),
                },
              },
            ],
          },
        ],
      },
      {
        path: `${PATHS.PUBLICATIONS_LIST.BASE}`,
        element: <Outlet />,
        errorElement: ERROR_PAGE(),
        id: PATHS.PUBLICATIONS_LIST.ID,
        title: PATHS.PUBLICATIONS_LIST.TITLE,
        loader: async ({ request }) => {
          const url = new URL(request.url).searchParams;
          const page = url.get(URL_QUERY_KEYS.PAGE_NUMBER);
          const filterString = convertFilterUrlToApi(url);
          const searchQuery = url.get(URL_QUERY_KEYS.SEARCH_TERM);
          return await publicationsLoader(parseInt(page), filterString, searchQuery);
        },
        shouldRevalidate: ({ nextUrl }) => {
          return nextUrl?.pathname === `/${PATHS.PUBLICATIONS_LIST.BASE}`;
        },
        handle: {
          crumb: new Crumb(false, PATHS.PUBLICATIONS_LIST.ID, PATHS.PUBLICATIONS_LIST.TITLE),
        },
        children: [
          {
            path: '',
            element: (
              <React.StrictMode>
                <PublicationsList />
              </React.StrictMode>
            ),
          },
          {
            path: `${PATHS.ANNUAL_REPORT.BASE}/:${PATHS.ANNUAL_REPORT.CHILDREN.COVER}`,
            element: <Outlet />,
            loader: ({ params }) => annualReportLoader(params),
            shouldRevalidate: (params) => {
              if (params) {
                const arUrlDifferent = params.currentParams?.arUrlSlug !== params.nextParams?.arUrlSlug;
                const entityUrlDifferent = params.currentParams?.entityUrlSlug !== params.nextParams?.entityUrlSlug;
                const portfolioUrlDifferent = params.currentParams?.portfolioUrlSlug !== params.nextParams?.portfolioUrlSlug;

                if (arUrlDifferent || entityUrlDifferent || portfolioUrlDifferent) {
                  // If any url param changes (linking to new annual report), revalidate to fetch to new report
                  return true;
                }
              }
              return false;
            },
            errorElement: ERROR_PAGE(),
            id: PATHS.ANNUAL_REPORT.ID,
            handle: {
              crumb: new Crumb(true, PATHS.ANNUAL_REPORT.ID),
              applyPrintStyle: true,
            },
            children: [
              {
                path: '',
                element: (
                  <React.StrictMode>
                    <AnnualReportCover />
                  </React.StrictMode>
                ),
              },
              {
                path: `:${PATHS.ANNUAL_REPORT.CHILDREN.REPORT_SECTION}`,
                element: <Outlet />,
                handle: {
                  crumb: new Crumb(true, PATHS.ANNUAL_REPORT.ID),
                  applyPrintStyle: true,
                },
                children: [
                  {
                    path: '',
                    element: <AnnualReportArticle />,
                  },
                  {
                    path: `:${PATHS.ANNUAL_REPORT.CHILDREN.REPORT_SUB_SECTION}`,
                    element: <AnnualReportArticle />,
                    handle: {
                      crumb: new Crumb(true, PATHS.ANNUAL_REPORT.ID),
                      applyPrintStyle: true,
                    },
                  },
                ],
              },
            ],
          },
        ],
      },
      {
        path: PATHS.SEARCH.BASE,
        element: (
          <React.StrictMode>
            <SearchPage />
          </React.StrictMode>
        ),
        errorElement: ERROR_PAGE(),
        id: PATHS.SEARCH.ID,
        loader: async ({ request }) => {
          const url = new URL(request.url).searchParams;
          const page = url.get(URL_QUERY_KEYS.PAGE_NUMBER);
          const filterString = convertFilterUrlToApi(url);
          const searchQuery = url.get(URL_QUERY_KEYS.SEARCH_TERM);
          return await searchLoader(parseInt(page), searchQuery, filterString);
        },
        shouldRevalidate: ({ nextUrl }) => {
          return nextUrl?.pathname === `/${PATHS.SEARCH.BASE}`;
        },
        handle: {
          crumb: new Crumb(false, PATHS.SEARCH.ID, PATHS.SEARCH.TITLE),
        },
      },
      {
        path: PATHS.STANDARD_PAGE.BASE,
        errorElement: ERROR_PAGE(),
        loader: ({ params }) => standardPageLoader(params[URL_PARAMS.STANDARD_PAGE]),
        id: PATHS.STANDARD_PAGE.ID,
        handle: {
          crumb: new Crumb(true, PATHS.STANDARD_PAGE.ID),
        },
        element: (
          <React.StrictMode>
            <Outlet />
          </React.StrictMode>
        ),
        children: [
          {
            path: '',
            element: <StandardPage dataFromLoader={PATHS.STANDARD_PAGE.ID} />,
          },
        ],
      },
      {
        path: PATHS.DATA_SETS.BASE,
        errorElement: ERROR_PAGE(),
        loader: () => dataSetsPageLoader(),
        shouldRevalidate: () => false,
        id: PATHS.DATA_SETS.ID,
        title: PATHS.DATA_SETS.TITLE,
        handle: {
          crumb: new Crumb(false, PATHS.DATA_SETS.ID, PATHS.DATA_SETS.TITLE),
        },
        element: (
          <React.StrictMode>
            <Outlet />
          </React.StrictMode>
        ),
        children: [
          {
            path: '',
            element: <DataSets />,
          },
          {
            path: PATHS.DATA_SETS.CHILDREN.DATA_SETS_VISUALISED.BASE,
            element: <DataSetsVisualised />,
            handle: {
              crumb: new Crumb(false, PATHS.DATA_SETS.CHILDREN.DATA_SETS_VISUALISED.ID, PATHS.DATA_SETS.CHILDREN.DATA_SETS_VISUALISED.TITLE),
            },
          },
          // {
          //   path: PATHS.DATA_SETS.CHILDREN.DATA_DEFINITIONS.BASE,
          //   element: <StandardPage dataFromLoader={PATHS.DATA_SETS.CHILDREN.DATA_DEFINITIONS.ID} />,
          //   id: PATHS.DATA_SETS.CHILDREN.DATA_DEFINITIONS.ID,
          //   loader: () => dataSetsPageDefinitionsLoader(),
          //   handle: {
          //     crumb: new Crumb(false, PATHS.DATA_SETS.CHILDREN.DATA_DEFINITIONS.ID, PATHS.DATA_SETS.CHILDREN.DATA_DEFINITIONS.TITLE),
          //   },
          // },
        ],
      },
      {
        path: PATHS.FINANCIAL_RATIOS.BASE,
        errorElement: ERROR_PAGE(),
        loader: () => financialRatiosPageLoader(),
        shouldRevalidate: () => false,
        id: PATHS.FINANCIAL_RATIOS.ID,
        title: PATHS.FINANCIAL_RATIOS.TITLE,
        handle: {
          crumb: new Crumb(false, PATHS.FINANCIAL_RATIOS.ID, PATHS.FINANCIAL_RATIOS.TITLE),
        },
        element: (
          <React.StrictMode>
            <Outlet />
          </React.StrictMode>
        ),
        children: [
          {
            path: '',
            element: <FinancialRatios />,
          },
        ],
      },
      {
        path: '*', // page not found
        element: PAGE_NOT_FOUND_PAGE(),
        handle: {
          crumb: new Crumb(false, undefined, PAGE_NOT_FOUND_TITLE),
        },
      },
    ],
  },
];

// add old annual report links redirect
ROUTES.push({
  path: `annual-reports/:${URL_PARAMS.ENTITY}/reporting-year/:${URL_PARAMS.YEAR}`,
  loader: ({ params }) => oldAnnualReportLinkRedirect(params),
  element: (
    <React.StrictMode>
      <LoadingAnnualReportPreview overrideMessage="Retrieving annual report..." />
    </React.StrictMode>
  ),
  // redirect all chapter and section sub links back to main page of annual report
  children: [
    {
      path: `:chapter`,
      loader: ({ params }) => oldAnnualReportLinkRedirect(params),
      element: (
        <React.StrictMode>
          <LoadingAnnualReportPreview overrideMessage="Retrieving annual report..." />
        </React.StrictMode>
      ),
      children: [
        {
          path: `:section`,
          loader: ({ params }) => oldAnnualReportLinkRedirect(params),
          element: (
            <React.StrictMode>
              <LoadingAnnualReportPreview overrideMessage="Retrieving annual report..." />
            </React.StrictMode>
          ),
        },
      ],
    },
  ],
});

if (IS_PREVIEW_MODE) {
  // only if preview mode, add contributer landing page path
  ROUTES.push({
    path: PATHS.CONTRIBUTER_LANDING_PAGE,
    id: PATHS.CONTRIBUTER_LANDING_PAGE,
    loader: async () => homepageLoader(true),
    element: (
      <React.StrictMode>
        <ContributerLandingPage />
      </React.StrictMode>
    ),
  });

  // add preview annual report path that redirect to correct annual report url
  ROUTES.push({
    path: `preview-annual-report/:${URL_PARAMS.CODENAME}`,
    loader: ({ params }) => previewAnnualReportRedirector(params),
    element: (
      <React.StrictMode>
        <LoadingAnnualReportPreview />
      </React.StrictMode>
    ),
  });

  // add preview table
  ROUTES.push({
    path: 'preview-table/:tableCodename',
    loader: ({ params }) => webDataTableLoader(params?.tableCodename),
    element: (
      <React.StrictMode>
        <TablePreview isPreview />
      </React.StrictMode>
    ),
    handle: {
      applyPrintStyle: true,
    },
  });
}

if (IS_FOR_EXPORT_PDF) {
  ROUTES.push(
    ...[
      {
        path: `pdf/${PATHS.ANNUAL_REPORT.BASE}/:${PATHS.ANNUAL_REPORT.CHILDREN.COVER}`,
        id: 'pdf',
        loader: ({ params }) => annualReportLoader(params),
        element: (
          <div id="TPApp" className="printOnly">
            <main id="main-content">
              <React.StrictMode>
                <AnnualReportArticle />
              </React.StrictMode>
            </main>
          </div>
        ),
        handle: {
          applyPrintStyle: true,
        },
      },
      {
        path: 'pdf/:tableCodename',
        loader: ({ params }) => webDataTableLoader(params?.tableCodename),
        element: (
          <React.StrictMode>
            <TablePreview />
          </React.StrictMode>
        ),
        handle: {
          applyPrintStyle: true,
        },
      },
    ],
  );
}

if (process.env.NODE_ENV !== 'production') {
  ROUTES[0].children.push({
    path: 'dummy',
    element: <Dummy />,
  });
}

export { ERROR_PAGE, PAGE_NOT_FOUND_PAGE, PATHS, ROUTES, URL_PARAMS };
