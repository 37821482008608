import { LOCAL_STORAGE_TIMEOUT, PUBLICATION_TYPE, PUBLICATIONS_LOCAL_STORAGE_KEY } from 'Constants';
import { getFromLocalStorage, saveToLocalStorage } from 'Utils/StorageUtils';

export const getPublicationType = (type) => {
  switch (type) {
    case PUBLICATION_TYPE.ANNUAL_REPORT.VALUE:
      return PUBLICATION_TYPE.ANNUAL_REPORT.TAG;
    case PUBLICATION_TYPE.CORPORATE_PLAN.VALUE:
      return PUBLICATION_TYPE.CORPORATE_PLAN.TAG;
    case PUBLICATION_TYPE.PORTFOLIO_BUDGET_STATEMENT.VALUE:
      return PUBLICATION_TYPE.PORTFOLIO_BUDGET_STATEMENT.TAG;
    default:
      return '';
  }
};

/**
 * Get publication data from local storage
 * @param {Number} pageNum - Page number to search for publication data
 * @returns {Object} If found publication data, will return object containing publications (array), totalItems (number) and timestamp (string)
 */
export const getPublicationsFromLocal = (pageNum) => {
  const publicationsInStorage = getFromLocalStorage(PUBLICATIONS_LOCAL_STORAGE_KEY);
  if (publicationsInStorage) {
    const currentPageInfo = publicationsInStorage[pageNum];
    if (currentPageInfo) {
      if (new Date() - new Date(currentPageInfo.timestamp) < LOCAL_STORAGE_TIMEOUT) {
        // If the date timestamp is less than 5 minutes, use this saved local data
        return {
          publications: currentPageInfo.publications,
          totalItems: currentPageInfo.totalItems,
          timestamp: currentPageInfo.timestamp,
          filter: currentPageInfo.filter,
          searchTerm: currentPageInfo.searchTerm,
        };
      }
    }
  }

  return null;
};

/**
 * Save publication data of a specific page to local storage
 * @param {Number} pageNum - Specific page number of publication data
 * @param {Array} publications - List of publications for this specific page
 * @param {Number} totalItems - Total number of items
 */
export const savePublicationsToLocal = (pageNum, publications, totalItems, filter, searchTerm) => {
  const publicationPageObject = {
    publications,
    totalItems,
    timestamp: new Date().toISOString(),
    filter: filter || '',
    searchTerm: searchTerm || '',
  };
  const publicationsInStorage = getFromLocalStorage(PUBLICATIONS_LOCAL_STORAGE_KEY);

  let publicationsToStore = {};
  if (publicationsInStorage) {
    publicationsToStore = { ...publicationsInStorage }; // Get all other page number data that is already stored
  }
  publicationsToStore[`${pageNum}`] = publicationPageObject;

  saveToLocalStorage(PUBLICATIONS_LOCAL_STORAGE_KEY, publicationsToStore);
};

export const isARType = (publicationType) => {
  // checks if current type is Annual Report
  return publicationType.toLowerCase() === PUBLICATION_TYPE.ANNUAL_REPORT.VALUE.toLowerCase();
};

export const isPBSType = (publicationType) => {
  // checks if current type is Portfolio Budget Statement
  return publicationType.toLowerCase() === PUBLICATION_TYPE.PORTFOLIO_BUDGET_STATEMENT.VALUE.toLowerCase();
};

export const isCPType = (publicationType) => {
  // checks if current type is Corporate Plan
  return publicationType.toLowerCase() === PUBLICATION_TYPE.CORPORATE_PLAN.VALUE.toLowerCase();
};
