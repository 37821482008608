import React, { Suspense } from 'react';
import { Await, Link, useRouteLoaderData } from 'react-router-dom';
import { ERROR_PAGE, PAGE_NOT_FOUND_PAGE, PATHS, PUBLICATION_TYPE, TAG_STYLE_ENUMS, URL_QUERY_KEYS } from 'Constants';
import { Tag } from 'Components';
import icons from 'Assets/svgSprite.svg';
import FullColourTree from 'Assets/Tree.svg';
import LightHalfTree from 'Assets/Tree-Half-Light.svg';
import { ReactComponent as TPLogo } from 'Assets/TPLogo.svg';
import style from './HomePage.module.scss';
import { FILTERABLE_GROUPS } from 'Constants/Search';
import { ERROR_CONTENT, PAGE_NOT_FOUND } from 'Constants/StaticContents';
import { STATUS_TYPES } from 'Utils/Error';
import { HomePageLoading } from 'Pages';

/**
 * HomePage.jsx
 *
 * @summary This component is page view for home page.
 */
export default function HomePage() {
  const { homepage } = useRouteLoaderData(PATHS.HOME.ID);

  // update title
  document.title = 'Transparency Portal';

  const publicationsPreFilter = [PUBLICATION_TYPE.ANNUAL_REPORT.VALUE, PUBLICATION_TYPE.CORPORATE_PLAN.VALUE, PUBLICATION_TYPE.PORTFOLIO_BUDGET_STATEMENT.VALUE];

  const backgroundImages = {
    hero: {
      '--BACKGROUND-IMAGE': `url(${FullColourTree})`,
    },
    section: {
      '--BACKGROUND-IMAGE': `url(${LightHalfTree})`,
    },
  };

  const applyFiltersToUrl = (url, filterToApply) => {
    // apply filters to url link to be used (not the to denote a filter, a '$' needs to be added)
    return `${url}?${encodeURIComponent(URL_QUERY_KEYS.FILTER_DENOTE)}${FILTERABLE_GROUPS.PUBLICATION_TYPE.VALUE}=${filterToApply}`;
  };

  return (
    <Suspense fallback={<HomePageLoading />}>
      <Await resolve={homepage} errorElement={ERROR_PAGE()}>
        {(resolvedData) => {
          if (resolvedData.isError) {
            if (resolvedData.statusCode === STATUS_TYPES.NOT_FOUND) return PAGE_NOT_FOUND_PAGE(ERROR_CONTENT.NOT_FOUND);
            if (resolvedData.statusCode === STATUS_TYPES.RANGE_ERROR) return ERROR_PAGE(PAGE_NOT_FOUND(ERROR_CONTENT.INVALID_CONTENT));
            if (resolvedData.statusCode === STATUS_TYPES.OTHER) return ERROR_PAGE();
            return ERROR_PAGE();
          }

          const {
            homepageMetadata: {
              heroBanner: { body, links },
              publicationsSection,
              homepageBodySection,
              relatedResources,
            },
          } = resolvedData;

          return (
            <>
              <div id={style.hero} className="fullColorBackground" style={backgroundImages.hero}>
                <div id={style.contentArea}>
                  <h1>
                    <span id="TP">Transparency Portal</span>
                    <TPLogo aria-describedby="TP" />
                  </h1>

                  {body}

                  <div>
                    <a id={style.jumpToPublicationsList} className="a" href={`#${style.publicationsList}`}>
                      Scroll down to find more
                      <svg>
                        <use href={icons + '#arrow'} />
                      </svg>
                    </a>
                  </div>
                </div>

                <div id={style.quickLinks}>
                  {links.map((obj) => (
                    <Link key={`quick-link-${obj?.title}`} to={obj?.url} className="card">
                      <span className="cardTitle">{obj?.title}</span>
                      <span className="description">{obj?.summary}</span>
                    </Link>
                  ))}
                </div>

                <div className={style.backgroundImage}>
                  <div className={style.imageContainer}>
                    <div className={style.tree}></div>
                  </div>
                  <div className={[style.imageContainer, style.transparentBackground].join(' ')}>
                    <div className={style.tree}></div>
                  </div>
                </div>
              </div>

              <section id={style.publicationsList}>
                <h2>{publicationsSection?.title}</h2>

                {publicationsSection?.columns?.map((obj, i) => (
                  <div key={`publication-type-${obj.title}`} className={style.publicationType}>
                    <h3 className={['h6', style.cardTitle].join(' ')}>
                      <Tag
                        displayText={obj.title
                          .split(' ')
                          .map((str) => str[0])
                          .join('')}
                        fullText={obj.title}
                        type={TAG_STYLE_ENUMS.HIGHLIGHT}
                      />
                      {obj.title}
                    </h3>

                    <Link className={['btn lightFill', style.link].join(' ')} to={applyFiltersToUrl(PATHS.PUBLICATIONS_LIST.BASE, publicationsPreFilter[i])}>
                      <svg>
                        <use href={icons + '#search'} />
                      </svg>
                      {`Search ${obj.title}`}
                    </Link>

                    <div>{obj?.node}</div>
                  </div>
                ))}
              </section>

              <section id={style.TPdescription} className="fullColorBackground" style={backgroundImages.section}>
                <div>
                  <h2>{homepageBodySection.title}</h2>
                  {homepageBodySection?.node}
                </div>
              </section>

              <section id={style.relatedResources}>
                <h2>Related resources to visit</h2>
                {relatedResources?.map((obj) => (
                  <a
                    key={`related-resource-${obj.title}`}
                    href={obj.url}
                    target={obj?.openInNewTab ? '_blank' : null}
                    rel={obj?.openInNewTab ? 'noreferrer' : null}
                    className="card">
                    <span className={obj?.openInNewTab ? 'newTabIconWrapper' : null}>
                      <span className="cardTitle">{obj.title}</span>
                      {obj?.openInNewTab && (
                        <svg className="newTabIcon">
                          <use href={icons + '#open-in-new-tab'} />
                        </svg>
                      )}
                    </span>
                    <span className="limitTextLines" style={{ '--MAX-LINE': 3 }}>
                      <span className="description">{obj.summary}</span>
                    </span>
                  </a>
                ))}
              </section>
            </>
          );
        }}
      </Await>
    </Suspense>
  );
}
